<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Trang chủ</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <span>Thông tin cá nhân</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div class="d-flex align-items-start">
                        <div
                            class="nav flex-column nav-pills"
                            style="width: 200px; padding-top: 20px; min-width: 200px"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <button
                                class="nav-link active"
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                Thông tin
                            </button>
                            <button
                                class="nav-link"
                                id="v-pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                            >
                                Đổi mật khẩu
                            </button>
                            <button
                                class="nav-link"
                                id="v-pills-plan-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-plan"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-plan"
                                aria-selected="false"
                            >
                                Plan
                            </button>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div
                                class="tab-pane fade show active"
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                                    <div class="statbox panel box box-shadow">
                                        <div class="panel-body">
                                            <div class="card">
                                                <div class="card-header">
                                                    <b style="color: #0a0d26">Cập nhật thông tin</b>
                                                </div>
                                                <div class="row col-12" style="padding: 30px">
                                                    <div
                                                        class="row col-lg-6 col-12 card-body d-flex"
                                                    >
                                                        <div class="mb-4 col-12">
                                                            Slug
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="slug"
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            Email
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="email"
                                                                    readonly
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            Youtube
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="youtube_url"
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            Designation
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="designation"
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    @click="updateProfile"
                                                                    class="btn btn-primary"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="row col-lg-6 col-12 card-body d-flex"
                                                    >
                                                        <div class="mb-4 col-12">
                                                            Name
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="name"
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            NimoTv Url
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="nimotv"
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            Facebook Url
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="facebook_url"
                                                                    type="text"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                <div id="tableProgress2" class="col-lg-12 col-12 layout-spacing">
                                    <div class="statbox panel box box-shadow">
                                        <div class="panel-body">
                                            <div class="card">
                                                <div class="card-header">
                                                    <b style="color: #0a0d26">Đổi mật khẩu</b>
                                                </div>
                                                <div class="row col-12" style="padding: 30px">
                                                    <div class="row col-12 card-body d-flex">
                                                        <div class="mb-4 col-12">
                                                            Mật khẩu hiện tại
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="oldPW"
                                                                    type="password"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            Nhập mật khẩu mới
                                                            <div class="d-flex">
                                                                <input
                                                                    v-model="newPW"
                                                                    type="password"
                                                                    class="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="mb-4 col-12">
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    @click="updatePW"
                                                                    class="btn btn-primary"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="v-pills-plan"
                                role="tabpanel"
                                aria-labelledby="v-pills-plan-tab"
                            >
                                <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                                    <div class="statbox panel box box-shadow">
                                        <div class="panel-body">
                                            <div class="card">
                                                <div class="card-header">
                                                    <b style="color: #0a0d26">Plan</b>
                                                </div>

                                                <div class="row col-12" style="padding: 30px">
                                                    <div
                                                        class="col-lg-4 col-12 card-body"
                                                        v-for="item in listProductPlan"
                                                    >
                                                        <div
                                                            v-show="item.active"
                                                            style="
                                                                /* width: 420px; */
                                                                height: 250px;
                                                                border: 1px solid grey;
                                                                border-radius: 30px;
                                                                padding: 30px;
                                                                display: flex;
                                                                flex-direction: column;
                                                                justify-content: space-between;
                                                            "
                                                        >
                                                            <span
                                                                style="
                                                                    font-weight: bold;
                                                                    font-size: 26px;
                                                                    color: black;
                                                                "
                                                                >{{ item.name }}</span
                                                            >
                                                            <div>
                                                                <span
                                                                    style="
                                                                        font-size: 36px;
                                                                        font-weight: bold;
                                                                        color: black;
                                                                    "
                                                                    >{{
                                                                        convertAmountTransaction(
                                                                            item.metadata
                                                                                .product_fee
                                                                        )
                                                                    }}đ</span
                                                                >
                                                            </div>
                                                            <div
                                                                style="
                                                                    display: flex;
                                                                    justify-content: space-between;
                                                                "
                                                            >
                                                                <span
                                                                    style="
                                                                        cursor: pointer;
                                                                        color: blue;
                                                                    "
                                                                    >Click to see more details</span
                                                                >
                                                                <button
                                                                    @click="
                                                                        () =>
                                                                            onUpdateProduct(
                                                                                item.id,
                                                                                item.default_price
                                                                            )
                                                                    "
                                                                    :class="{
                                                                        btn: true,
                                                                        'btn-primary':
                                                                            productId === item.id,
                                                                        'btn-outline-primary':
                                                                            productId !== item.id,
                                                                        disabled:
                                                                            productId === item.id,
                                                                    }"
                                                                >
                                                                    {{
                                                                        productId === item.id
                                                                            ? 'Current plan'
                                                                            : 'Choose plan'
                                                                    }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Thông tin cá nhân' });
</script>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import $ from 'jquery';
    import actions from '../../../store/moduleCloud/actions';

    export default {
        name: 'Target',
        data() {
            return {
                slug: '',
                email: '',
                youtube_url: '',
                designation: '',
                name: '',
                nimotv: '',
                facebook_url: '',
                info: null,
                oldPW: '',
                newPW: '',
                listProductPlan: [],
                productId: null,
                paymentMethod: '',
            };
        },
        mounted() {
            $('.modal-backdrop').remove();
        },
        created() {
            this.$store.commit('SHOW_LOADING', true);
            this.getMeDonate({})
                .then((res) => {
                    // console.log(res)
                    let info = res.data.user;
                    this.info = info;
                    this.slug = info.slug;
                    this.email = info.email;
                    this.name = info.name;
                    this.youtube_url = info.youtube_url;
                    this.designation = info.designation;
                    this.facebook_url = info.facebook_url;
                    this.nimotv = info.nimotv;
                    this.productId = info.product_id;
                    this.paymentMethod = info.payment_method
                    this.getProducts()
                        .then((res) => {
                            this.listProductPlan = res.data.data;
                            this.$store.commit('SHOW_LOADING', false);
                        })
                        .catch((e) => {
                            console.log(e);
                            this.$store.commit('SHOW_LOADING', false);
                        });
                })
                .catch((e) => {
                    this.$store.commit('SHOW_LOADING', false);
                    console.log(e);
                });
        },
        computed: {
            ...mapGetters(['getterCurrency']),
        },
        methods: {
            ...mapActions(['getMeDonate', 'updateProfileDonate', 'getProducts', 'updateProducts']),
            onUpdateProduct(id, price) {
                this.$store.commit('SHOW_LOADING', true);
                actions.postSubscriptions({code: price, defaultPaymentMethod: this.paymentMethod}).then(() => {
                    this.updateProducts(id, price).then(() => {
                        this.getMeDonate({})
                            .then((res) => {
                                let info = res.data.user;
                                this.info = info;
                                this.slug = info.slug;
                                this.email = info.email;
                                this.name = info.name;
                                this.youtube_url = info.youtube_url;
                                this.designation = info.designation;
                                this.facebook_url = info.facebook_url;
                                this.nimotv = info.nimotv;
                                this.productId = info.product_id;
                                this.$store.commit('SHOW_LOADING', false);
                                this.$store.dispatch('showNotification', {
                                    title: 'Success !',
                                    type: 'success',
                                    text: 'Cập nhật thành công !',
                                });
                            })
                            .catch((e) => {
                                console.log(e);
                                this.$store.dispatch('showNotification', {
                                    title: 'Error !',
                                    type: 'error',
                                    text: res.data.error,
                                });
                                this.$store.commit('SHOW_LOADING', false);
                            });
                    });
                });
            },
            convertAmountTransaction(x) {
                return parseInt(x).toLocaleString('vi-VN');
            },
            convertCurrency(x) {
                return x.toLocaleString('vi-VN');
            },
            formatCreatedAt(date) {
                date = new Date(date);
                let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return (
                    date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s
                );
            },
            updatePW() {
                let obj = {
                    RetypePassword: this.newPW,
                    newPasswordValue: this.newPW,
                    passwordValueOld: this.oldPW,
                    type: 'change_password',
                };
                this.updateProfileDonate(obj)
                    .then((res) => {
                        if (!res.data.result) {
                            this.$store.dispatch('showNotification', {
                                title: 'Error !',
                                type: 'error',
                                text: res.data.error,
                            });
                            return false;
                        } else {
                            this.$store.dispatch('showNotification', {
                                title: 'Success !',
                                type: 'success',
                                text: 'Cập nhật thành công !',
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });
            },
            updateProfile() {
                let obj = {
                    ...this.info,
                    slug: this.slug,
                    email: this.email,
                    name: this.name,
                    youtube_url: this.youtube_url,
                    designation: this.designation,
                    facebook_url: this.facebook_url,
                    nimotv: this.nimotv,
                    type: 'general',
                };
                this.updateProfileDonate(obj)
                    .then((res) => {
                        if (!res.data.result) {
                            this.$store.dispatch('showNotification', {
                                title: 'Error !',
                                type: 'error',
                                text: res.data.error,
                            });
                            return false;
                        } else {
                            this.$store.dispatch('showNotification', {
                                title: 'Success !',
                                type: 'success',
                                text: 'Cập nhật thành công !',
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });
            },
        },
    };
</script>

<style scoped>
    .table > tbody > tr > td {
        padding: 13px;
    }

    .panel {
        border: none !important;
        background-color: unset !important;
    }
</style>
