import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Notifications from '@kyvg/vue3-notification';

const app = createApp(App);

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';

window.$appSetting = appSetting;
window.$appSetting.init();

// import firebaseMessaging from './firebase';

import VueAnalytics from 'vue3-analytics';

//socket
import VueSocketIO from 'wsj.vue-socket.io'
import SocketIO from 'socket.io-client'

// window.$appSetting = appSetting;
// window.$appSetting.init();

// app.config.globalProperties.$messaging = firebaseMessaging;
app.use(store)
    .use(new VueSocketIO({ debug: true, connection: SocketIO('https://socket-payment-donate.myepis.cloud/') }))
    .use(VueAnalytics, {
        id: 'G-P5ZCH9426Y',
        router
    })
    .use(router)
    .use(i18n)
    .use(PerfectScrollbar)
    .use(VueNouislider)
    .use(Maska)
    .use(ClientTable)
    .use(vue3JsonExcel)
    .use(VueFormWizard)
    .use(head)
    .use(Notifications)
    .mount('#app');
