<template>
    <div class="" style="margin-top: 50px">
        <div class="row">
            <div class="col-2">
                <Sidebar></Sidebar>
            </div>
            <div class="col-10">
                <step-progress
                    :steps="stepName"
                    :current-step="stepIndex"
                    active-color="green"
                    line-thickness="5"
                    icon-class="fa fa-check"
                ></step-progress>

                <div class="stepper-content">
                    <div v-if="stepIndex === 0">
                        <Step1 @onChangeStep="(e) => onChangeStep(e, 1)" />
                    </div>

                    <div
                        v-if="stepIndex === 1"
                        class="d-flex justify-content-center align-items-center"
                        style="margin-top: 200px"
                    >
                        <Step2
                            :onChangeStep="(e) => onChangeStep(e, 2)"
                            :defaultPrice="defaultPrice"
                            :productId="productId"
                        />
                    </div>

                    <div
                        v-if="stepIndex === 2"
                        class="d-flex justify-content-center align-items-center"
                        style="margin-top: 200px"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent, ref } from 'vue';
    import Plan from './Plan.vue';
    import Step2 from './Step2.vue';
    import StepProgress from 'vue-step-progress';
    import actions from '../store/moduleCloud/actions';
    import { useStore } from 'vuex';
    import router from '@/router';
    import 'vue-step-progress/dist/main.css';
    import Sidebar from '@/components/layout/planSidebar.vue';

    export default defineComponent({
        name: 'ProductService',
        components: {
            Sidebar,
            Step1: Plan,
            Step2,
            'step-progress': StepProgress,
        },
        setup() {
            const { payment_method } = JSON.parse(sessionStorage.getItem('userData'));
            const store = useStore();
            const stepIndex = ref(0);
            const defaultPrice = ref('');
            const productId = ref('');
            const stepName = ref(['Đăng ký gói', 'Tạo phương thức', 'Khởi tạo']);

            const handleApi = (params) => {
                store.commit('SHOW_LOADING', true);
                actions
                    .updatePaymentMethod(params)
                    .then(() => {
                        actions
                            .updateProducts(null, productId.value, defaultPrice.value)
                            .then(() => {
                                actions
                                    .postSubscriptions({
                                        code: defaultPrice.value,
                                        defaultPaymentMethod: params,
                                    })
                                    .then(() => {
                                        store.commit('SHOW_LOADING', false);

                                        router.push('/');
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        store.commit('SHOW_LOADING', false);
                                    });
                            })
                            .catch((e) => {
                                console.log(e);
                                store.commit('SHOW_LOADING', false);
                            });
                    })
                    .catch((e) => {
                        console.log(e);
                        store.commit('SHOW_LOADING', false);
                    });
            };

            const onChangeStep = (params, index) => {
                if (index === 2) {
                    handleApi(params);
                }

                if (index === 1) {
                    defaultPrice.value = params.defaultPrice;
                    productId.value = params.productId;

                    if (payment_method !== '' && payment_method !== null) {
                        stepIndex.value = 2;
                        handleApi(payment_method);
                        return;
                    }
                }

                stepIndex.value = index;
            };

            return {
                stepIndex,
                defaultPrice,
                productId,
                onChangeStep,
                stepName,
            };
        },
    });
</script>
<style>
    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
    }
    .step-button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        background-color: var(--prm-gray);
        transition: 0.4s;
    }
    .step-button[aria-expanded='true'] {
        width: 60px;
        height: 60px;
        background-color: var(--prm-color);
        color: #fff;
    }
    .done {
        background-color: var(--prm-color);
        color: #fff;
    }
    .step-item {
        z-index: 10;
        text-align: center;
    }
    #progress {
        -webkit-appearance: none;
        position: absolute;
        width: 95%;
        z-index: 5;
        height: 10px;
        margin-left: 18px;
        margin-bottom: 18px;
    }
    /* to customize progress bar */
    #progress::-webkit-progress-value {
        background-color: var(--prm-color);
        transition: 0.5s ease;
    }
    #progress::-webkit-progress-bar {
        background-color: var(--prm-gray);
    }
</style>
