<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Trang chủ</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Cập nhật thông tin rút tiền</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="d-flex justify-content-between">
                                   <div>
                                       <h4>Cập nhật thông tin rút tiền</h4>
                                   </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="card">
                                    <div class="card-header">
                                        <b style="color: #0a0d26">Thông tin ngân hàng</b>
                                    </div>
                                    <div class="row card-body d-flex">
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Ngân hàng</label>
                                            <div>
                                                <select v-model="bankid" class="form-control">
                                                    <option v-for="(option, k) in bankList" v-bind:key="k" v-bind:value="option.id"> {{ option.vietnamese_name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Tên chủ tài khoản</label>
                                            <div>
                                                <input v-model="name" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Số tài khoản</label>
                                            <div>
                                                <input v-model="number" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <div>
                                                <button type="button" @click="createRecord" class="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Cập nhật thông tin rút tiền' });
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'
export default {
    name: "BankEdit",
    data() {
        return {
            domainDetail: null,
            name: this.$route.query.full_name,
            type: "A",
            content: "",
            ttl: "",
            proxied: true,
            number: this.$route.query.number_id,
            bankid: this.$route.query.bank_id,
            address: this.$route.query.branch,
            bankList: []
            
        }
    },
    mounted() {
        $(".modal-backdrop").remove()
        
    },
    created() {
        console.log()
        console.log(this.$route.query.full_name)
        this.getBankList({}).then(res => {
            this.bankList = res.data.data
        }).catch(e => {
            console.log(e)
        })
    },
    computed:{
        ...mapGetters(['getterMe','getterCurrency']),
        getContentIP(){
            switch(this.type) {
                case 'A':
                    return {txt:'IPV4',type: 'text'}
                case 'AAAA':
                    return {txt:'IPV6',type: 'text'}
                case 'TXT':
                    return {txt:'Content',type: 'text'}
                case 'MX':
                    return {txt:'Mail Server',type: 'text'}
                case 'NS':
                    return {txt:'Name Server',type: 'text'}
                case 'CNAME':
                    return {txt:'Target',type: 'text'}
            }
        }
    },
    methods: {
        ...mapActions(['getDetailDNS','updateRecordBank','getBankList']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date){
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        createRecord(){
            if (this.name === '' || this.bankid === '' || this.number === ''){
                this.$store.dispatch('showNotification',{
                    title: 'Error !',
                    type: 'error',
                    text: 'Please input info record !',
                })
                return false;
            }
            let obj = {
                "id": this.$route.query.id,
                "full_name": this.name,
                "bank_id": this.bankid,
                "branch": this.address,
                "number_id": this.number,
            }
            this.updateRecordBank(obj).then(res => {
                console.log('updateRecordBank',res)
                if (!res.data.result){
                    this.$store.dispatch('showNotification',{
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                }else{
                    this.$store.dispatch('showNotification',{
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật tài khoản ngân hàng thành công !',
                    })
                    this.$router.push('/banks')
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}
.panel{
    border: none !important;
    background-color: unset !important;
}
</style>
