<template>
    <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
    >
        <StripeElement ref="card" :elements="elements" :options="cardOptions" />
        <button style="width: 100%" class="btn btn-primary mt-2" @click="pay">
            Tạo phương thức
        </button>
    </StripeElements>
</template>

<script>
    import { defineComponent, ref, onBeforeMount } from 'vue';
    import { loadStripe } from '@stripe/stripe-js';
    import StripeElements from './StripeElements.vue';
    import StripeElement from './StripeElement.vue';
    import '@/assets/sass/scrollspyNav.scss';
    import '@/assets/sass/users/user-profile.scss';
    // import actions from '../store/moduleCloud/actions';

    export default defineComponent({
        name: 'CardOnly',
        components: {
            StripeElements,
            StripeElement,
        },
        props: {
            defaultPrice: String,
            productId: String,
            onChangeStep: Function
        },
        setup() {
            const stripeKey = ref(process.env.VUE_APP_PAYMENT_STRIPE_KEY); // test key
            const instanceOptions = ref({
                // https://stripe.com/docs/js/initializing#init_stripe_js-options
            });
            const elementsOptions = ref({
                // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
            });
            const cardOptions = ref({
                // https://stripe.com/docs/stripe.js#element-options
                value: {
                    postalCode: '12345',
                },
            });
            const stripeLoaded = ref(false);
            const card = ref();
            const elms = ref();
            onBeforeMount(() => {
                const stripePromise = loadStripe(stripeKey.value);
                stripePromise.then(() => {
                    stripeLoaded.value = true;
                });
            });
            return {
                stripeKey,
                stripeLoaded,
                instanceOptions,
                elementsOptions,
                cardOptions,
                card,
                elms,
            };
        },
        methods: {
            pay() {
                const cardElement = this.card.stripeElement;
                this.elms.instance
                    .createPaymentMethod({
                        card: cardElement,
                        type: 'card',
                        billing_details: {
                            name: '',
                        },
                    })
                    .then((result) => {
                        console.log(result)
                        this.onChangeStep(result.paymentMethod.id)
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
        },
    });
</script>
