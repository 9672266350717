<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-view to="/">Trang chủ</router-view>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <span>Thông báo Donate</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <NotificationTab />
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="card">
                                    <div class="card-header">
                                        <b style="color: #0a0d26">Thông báo donate</b>
                                    </div>
                                    <div class="row card-body d-flex">
                                        <div class="col-lg-6 col-12">
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label"
                                                    >URL Donate
                                                    <button
                                                        @click="refreshToken()"
                                                        class="btn btn-secondary"
                                                    >
                                                        <i
                                                            class="px-2 fa-solid fa-arrows-rotate"
                                                        ></i
                                                        >Làm mới
                                                    </button>
                                                    <button
                                                        @click="testNotify"
                                                        class="btn btn-secondary mx-3"
                                                    >
                                                        <i
                                                            class="px-2 fa-solid fa-arrows-rotate"
                                                        ></i
                                                        >Donate test
                                                    </button>
                                                </label>
                                                <div>
                                                    <input
                                                        v-model="url_donate"
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label"
                                                    >Nội dung hiển thị</label
                                                >
                                                <div>
                                                    <input
                                                        v-model="message_template"
                                                        type="text"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label">Kiểu hiển thị</label>
                                                <div>
                                                    <select
                                                        v-model="template"
                                                        class="form-control"
                                                        id="__BVID__94"
                                                    >
                                                        <option :value="position[0].id">
                                                            {{ position[0].text }}
                                                        </option>
                                                        <option :value="position[1].id">
                                                            {{ position[1].text }}
                                                        </option>
                                                        <option :value="position[2].id">
                                                            {{ position[2].text }}
                                                        </option>
                                                        <option :value="position[3].id">
                                                            {{ position[3].text }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label">Lọc từ</label>
                                                <div>
                                                    <multiselect
                                                        v-model="str_filter"
                                                        :options="str_filter"
                                                        :multiple="true"
                                                        :taggable="true"
                                                        :searchable="true"
                                                        placeholder="Choose..."
                                                        selected-label=""
                                                        select-label=""
                                                        deselect-label=""
                                                        @tag="addTag"
                                                    ></multiselect>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label">Ảnh hiển thị</label>
                                                <div>
                                                    <input
                                                        class="form-control"
                                                        type="file"
                                                        id="formFile"
                                                        :onchange="onChangeIconUrl"
                                                    />
                                                </div>
                                                <!-- <img
                                                    :src="getImgUrl"
                                                    :alt="icon_url"
                                                    style="height: 100px; object-fit: cover"
                                                /> -->
                                            </div>

                                            <div class="mb-4 col-12">
                                                <label class="col-form-label">Trạng Thái</label>
                                                <div>
                                                    <label
                                                        class="switch s-icons s-outline s-outline-secondary me-2"
                                                    >
                                                        <input v-model="status" type="checkbox" />
                                                        <span class="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label">Chuông</label>
                                                <div>
                                                    URL :
                                                    <a
                                                        class="font-weight-bold text-info"
                                                        :href="sound_url"
                                                        >{{ sound_url }}</a
                                                    >
                                                    <input class="form-control mt-2" type="file" />
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label"
                                                    >Âm lượng chuông
                                                    <span
                                                        class="badge badge-info range-count-number"
                                                        >{{ sound_volume }}</span
                                                    >
                                                </label>
                                                <div>
                                                    <div
                                                        class="custom-progress progress-up"
                                                        style="width: 100%"
                                                    >
                                                        <input
                                                            type="range"
                                                            v-model="sound_volume"
                                                            :min="0"
                                                            :max="10"
                                                            class="progress-range-counter form-range"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label"
                                                    >Độ trễ thông báo
                                                    <span
                                                        class="badge badge-info range-count-number"
                                                        >{{ alert_delay }}s</span
                                                    >
                                                </label>
                                                <div>
                                                    <div
                                                        class="custom-progress progress-up"
                                                        style="width: 100%"
                                                    >
                                                        <input
                                                            type="range"
                                                            v-model="alert_delay"
                                                            :min="0"
                                                            :max="100"
                                                            class="progress-range-counter form-range"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label">WGU đọc</label>
                                                <div>
                                                    <label
                                                        class="switch s-icons s-outline s-outline-secondary mb-4 me-2"
                                                    >
                                                        <input
                                                            v-model="google_speech"
                                                            type="checkbox"
                                                        />
                                                        <span class="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <label class="col-form-label"
                                                    >Âm lượng WGU đọc
                                                    <span
                                                        class="badge badge-info range-count-number"
                                                        >{{ text_to_speech_volume }}</span
                                                    >
                                                </label>
                                                <div>
                                                    <div
                                                        class="custom-progress progress-up"
                                                        style="width: 100%"
                                                    >
                                                        <input
                                                            type="range"
                                                            v-model="text_to_speech_volume"
                                                            :min="0"
                                                            :max="10"
                                                            class="progress-range-counter form-range"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <div :class="getPosition">
                                                <div>
                                                    <img
                                                        :src="getImgUrl"
                                                        alt=""
                                                        style="height: 100px; object-fit: cover"
                                                    />
                                                </div>
                                                <span class="mx-3">{{ message_template }}</span>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <div>
                                                <button
                                                    type="button"
                                                    @click="updateDonate"
                                                    class="btn btn-primary"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import NotificationTab from '../../../components/layout/notificationsTab.vue';

    import '@/assets/sass/scrollspyNav.scss';
    import '@/assets/sass/forms/file-upload-with-preview.min.css';
    // import Multiselect from '@suadelabs/vue3-multiselect';
    import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
    import '@/assets/sass/forms/bootstrap-slider.css';

    // useMeta({ title: 'Thông báo donate' });
    import { mapActions, mapGetters } from 'vuex';
    import $ from 'jquery';

    export default {
        name: 'NotiDonate',
        components: {
            NotificationTab
        },
        data() {
            return {
                inputs: {
                    input4: [],
                },
                slider1: 0,
                domainDetail: null,
                name: this.$route.query.full_name,
                type: 'A',
                content: '',
                ttl: '',
                proxied: true,
                number: this.$route.query.number_id,
                bankid: this.$route.query.bank_id,
                address: this.$route.query.branch,
                bankList: [],

                //
                alert_delay: 0,
                google_speech: false,
                icon_url: '',
                message_template: '',
                sound_url: '',
                sound_volume: 0,
                status: true,
                str_filter: [],
                template: 0,
                text_to_speech_volume: 0,
                id: '',
                url_donate: '',
                token: '',
                position: [
                    { id: 'icon_top_text_bottom', text: 'Icon ở trên, chữ ở dưới' },
                    { id: 'icon_left_text_right', text: 'Icon ở bên trái, chữ ở bên phải' },
                    { id: 'icon_right_text_left', text: 'Icon ở bên phải, chữ ở bên trái' },
                    { id: 'icon_bottom_text_top', text: 'Icon ở dưới, chữ ở trên' },
                ],
            };
        },
        mounted() {
            $('.modal-backdrop').remove();
        },
        created() {
            //TODO
            // this.donateSettingDetail().then((res) => {
            //     if (res.data.success && res.data.data) {
            //         TODO
            //         let info = res.data.data;
            //         this.status = info.status;
            //         this.str_filter = info.str_filter;
            //         this.template = info.template;
            //         this.text_to_speech_volume = info.text_to_speech_volume;
            //         this.alert_delay = info.alert_delay;
            //         this.google_speech = info.google_speech;
            //         this.icon_url = info.icon_url;
            //         this.message_template = info.message_template;
            //         this.sound_url = info.sound_url;
            //         this.sound_volume = info.sound_volume;
            //     }
            // });
            this.getWidgetsDonate({})
                .then((res) => {
                    let info = res.data.json;

                    this.status = info.status;
                    this.str_filter = info.str_filter;
                    this.template = info.template;
                    this.text_to_speech_volume = info.text_to_speech_volume;
                    this.alert_delay = info.alert_delay;
                    this.google_speech = info.google_speech;
                    this.icon_url = info.icon_url;
                    this.message_template = info.message_template;
                    this.sound_url = info.sound_url;
                    this.sound_volume = info.sound_volume;

                    this.id = res.data.id;
                    this.token = res.data.token;
                    this.url_donate = `${process.env.VUE_APP_URL}screens/donate/${res.data.token}`;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        computed: {
            ...mapGetters(['getterMe', 'getterCurrency']),
            getPosition() {
                let string = '';
                console.log(this.template);
                switch (this.template) {
                    case 'icon_top_text_bottom':
                        string =
                            'd-flex justify-content-center mt-5 flex-column align-items-center';
                        break;
                    case 'icon_left_text_right':
                        string = 'd-flex justify-content-center mt-5';
                        break;
                    case 'icon_bottom_text_top':
                        string =
                            'd-flex justify-content-center mt-5 flex-column-reverse align-items-center';
                        break;
                    case 'icon_right_text_left':
                        string = 'd-flex justify-content-center mt-5 flex-row-reverse';
                        break;
                }
                return string;
            },
            getImgUrl() {
                return 'https://i.imgflip.com/37omml.jpg';
            },
        },
        methods: {
            ...mapActions([
                'getDetailDNS',
                'updateDonateSetting',
                'getWidgetsDonate',
                'testNotifyAction',
                'getDetail',
                'donateSettingDetail',
                'uploadImage',
            ]),
            onChangeIconUrl(data) {
                const formData = new FormData();
                Array.from(Array(data.target.files.length).keys()).map((x) => {
                    formData.append('file', data.target.files[x], data.target.files[x].name);
                });

                this.uploadImage({ formData, slug: 'icon_url' }).then((e) => {
                    this.icon_url = e.data.data;
                });
            },
            async testNotify() {
                const payload = {
                    alert_delay: this.alert_delay,
                    google_speech: this.google_speech,
                    icon_url: this.icon_url,
                    message_template: this.message_template,
                    sound_url: this.sound_url,
                    sound_volume: this.sound_volume,
                    status: this.status,
                    str_filter: this.str_filter,
                    template: this.template,
                    text_to_speech_volume: this.text_to_speech_volume,
                    test: true,
                    id: this.id,
                    token: this.token,
                };
                await this.testNotifyAction(payload);
            },
            convertAmountTransaction(x) {
                return parseInt(x).toLocaleString('vi-VN');
            },
            convertCurrency(x) {
                return x.toLocaleString('vi-VN');
            },
            formatCreatedAt(date) {
                date = new Date(date);
                let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return (
                    date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s
                );
            },
            addTag(newTag) {
                this.str_filter.push(newTag);
            },
            refreshToken() {
                let obj = {
                    token: true,
                };
                this.updateDonateSetting(obj)
                    .then((res) => {
                        console.log('updateDonateSetting', res);
                        if (!res.data.result) {
                            this.$store.dispatch('showNotification', {
                                title: 'Error !',
                                type: 'error',
                                text: res.data.error,
                            });
                            return false;
                        } else {
                            this.token = res.data.data;
                            this.url_donate = `${process.env.VUE_APP_URL}screens/donate/${res.data.data}`;
                            this.$store.dispatch('showNotification', {
                                title: 'Success !',
                                type: 'success',
                                text: 'Cập nhật thành công !',
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });
            },
            updateDonate() {
                let obj = {
                    alert_delay: this.alert_delay,
                    google_speech: this.google_speech,
                    icon_url: this.icon_url,
                    message_template: this.message_template,
                    sound_url: this.sound_url,
                    sound_volume: this.sound_volume,
                    status: this.status,
                    str_filter: this.str_filter,
                    template: this.template,
                    text_to_speech_volume: this.text_to_speech_volume,
                };
                this.updateDonateSetting(obj)
                    .then((res) => {
                        console.log('updateDonateSetting', res);
                        if (!res.data.result) {
                            this.$store.dispatch('showNotification', {
                                title: 'Error !',
                                type: 'error',
                                text: res.data.error,
                            });
                            return false;
                        } else {
                            this.$store.dispatch('showNotification', {
                                title: 'Success !',
                                type: 'success',
                                text: 'Cập nhật thành công !',
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });
            },
        },
    };
</script>

<style scoped>
    .table > tbody > tr > td {
        padding: 13px;
    }

    .panel {
        border: none !important;
        background-color: unset !important;
    }
</style>
