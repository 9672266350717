<template>
    <div class="layout-px-spacing">
       <FinanceTab />
        <div class="layout-top-spacing d-flex justify-content-between">
            <h4>Hoá đơn</h4>
        </div>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <v-client-table
                                        ref="myTable"
                                        :data="items"
                                        :columns="columns"
                                        :options="table_option"
                                    >
                                        <template #hosted_invoice_url="props">
                                            <button
                                                class="btn btn-outline-primary"
                                                @click="
                                                    () =>
                                                        onRedirectToInvoice(
                                                            props.row.hosted_invoice_url
                                                        )
                                                "
                                            >
                                                Thanh toán
                                            </button>
                                        </template>

                                        <template #status="props">
                                            <button
                                                class="btn"
                                                :class="{
                                                    'btn-primary': props.row.status === 'open',
                                                    'btn-danger': props.row.status === 'void',
                                                    'btn-success': props.row.status === 'paid',
                                                }"
                                                style="width: 150px"
                                            >
                                                {{ handleStatus(props.row.status) }}
                                            </button>
                                        </template>

                                        <template #amount_due="props">
                                            {{ convertAmountTransaction(props.row.amount_due) }}đ
                                        </template>

                                        <template #amount_paid="props">
                                            {{ convertAmountTransaction(props.row.amount_paid) }}đ
                                        </template>
                                    </v-client-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import actions from '../../../store/moduleCloud/actions';
    import FinanceTab from '../../../components/layout/financeTab.vue'

    import moment from 'moment';

    const store = useStore();
    const columns = ref(['id', 'amount_due', 'amount_paid', 'hosted_invoice_url', 'status']);
    const items = ref([]);
    // const isCheck = ref(1);
    const table_option = ref({
        perPage: 10,
        filterable: 0,
        headings: {
            id: 'Mã hoá đơn',
            amount_due: 'Tiền cần thanh toán',
            amount_paid: 'Tiền đã thanh toán',
            hosted_invoice_url: 'Link hoá đơn',
            status: 'Trạng thái',
        },
        perPageValues: [],
        // skin: 'table table-hover',
        // columnsClasses: { actions: 'actions text-center' },
        // sortable: [],
        // pagination: { nav: 'scroll', chunk: 5 },
        // texts: {
        //     count: 'Showing {from} to {to} of {count}',
        //     filter: '',
        //     filterPlaceholder: 'Search...',
        //     limit: 'Results:',
        // },
        // sortIcon: {
        //     base: 'sort-icon-none',
        //     up: 'sort-icon-asc',
        //     down: 'sort-icon-desc',
        // },
        // filterable: [],
        // resizableColumns: false,
    });

    onMounted(() => {
        getData();
    });

    const getData = async () => {
        try {
            store.commit('SHOW_LOADING', true);
            const res = await actions.getInvoice();

            items.value = res.data.data;
            store.commit('SHOW_LOADING', false);
        } catch (_) {}
    };

    const onRedirectToInvoice = (url) => {
        location.href = url;
    };
    const convertAmountTransaction = (x) => {
        return parseInt(x).toLocaleString('vi-VN');
    };

    const handleStatus = (status) => {
        let string;
        switch (status) {
            case 'open':
                string = 'Chưa thanh toán';
                break;
            case 'paid':
                string = 'Đã thanh toán';
                break;
            case 'void':
                string = 'Đã huỷ';
                break;
            default:
                string = '';
                break;
        }
        return string;
    };
</script>
<style scoped></style>
