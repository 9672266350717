// import type {
//   Stripe,
//   StripeConstructorOptions,
//   StripeElementsOptions,
//   StripeElements,
//   StripeElementType,
// } from '@stripe/stripe-js'

// import type {
//   StripeElementsWithoutOverload,
//   StripeElementOptions,
// } from '../types/vue-stripe'

export const ERRORS = {
    STRIPE_NOT_LOADED: 'Stripe v3 library is not loaded',
    INSTANCE_NOT_DEFINED:
        'Instance object is not defined. Make sure you initialized Stripe before creating elements',
    ELEMENTS_NOT_DEFINED:
        "Elements object is not defined. You can't create stripe element without it",
    ELEMENT_TYPE_NOT_DEFINED: "elementType is required. You can't create stripe element without it",
};

export const initStripe = (key, options) => {
    try {
        if (!window.Stripe) {
            throw new Error(ERRORS.STRIPE_NOT_LOADED);
        }
        const stripeInstance = window.Stripe(key, options);
        return stripeInstance;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const createElements = (instance, options) => {
    try {
        if (!instance) {
            throw new Error(ERRORS.INSTANCE_NOT_DEFINED);
        }
        const elements = instance.elements(options);
        return elements;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const createElement = (elements, elementType, options) => {
    try {
        if (!elements) {
            throw new Error(ERRORS.ELEMENTS_NOT_DEFINED);
        }
        if (!elementType) {
            throw new Error(ERRORS.ELEMENT_TYPE_NOT_DEFINED);
        }
        const element = elements.create(elementType, {
            hidePostalCode: true,
            style: {
                base: {
                    color: '#32325D',
                    fontWeight: 500,
                    fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    '::placeholder': {
                        color: '#CFD7DF',
                    },
                },
                invalid: {
                    color: '#E25950',
                },
            },
        });
        return element;
    } catch (error) {
        console.error(error);
        return error;
    }
};
