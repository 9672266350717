<template>
    <div style="margin: 20px 0">
        <div class="d-flex">
            <div class="tab-custom" :class="activeKey === '1' ? 'active' : ''" @click="changeActiveKey('1')">Thông báo donate</div>
        </div>
    </div>
</template>
<script>
    import { defineComponent, ref, onMounted, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    const TabEnum = {
        Donate: '/notifications/donate',
    };

    export default defineComponent({
        setup() {
            const activeKey = ref('1');
            const route = useRoute();
            const router = useRouter();

            onMounted(() => {
                const path = route.fullPath;
                switch (path) {
                    case TabEnum.Donate:
                        activeKey.value = '1';
                        break;

                    default:
                        activeKey.value = '1';
                        break;
                }
            });

            watch(activeKey, () => {
                if (activeKey.value === '1') {
                    router.push(TabEnum.Transactions);
                }
            });

            const changeActiveKey = (key) => {
                activeKey.value = key;
            }
            return {
                activeKey,
                changeActiveKey
            };
        },
    });
</script>
