<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <span>Reconciliation</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <FinanceTab />
        <div class="layout-top-spacing d-flex justify-content-between">
            <h4>Đối soát</h4>
            <div>
                <select v-model="isCheck" class="form-control">
                    <option :value="1">Đã đối soát</option>
                    <option :value="2">Chưa đối soát</option>
                </select>
            </div>
        </div>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <v-client-table
                                        ref="myTable"
                                        :data="items"
                                        :columns="columns"
                                        :options="table_option"
                                    >
                                        <template #status="props">
                                            {{
                                                props.row.status === 1
                                                    ? 'Đã đối soát'
                                                    : 'Chưa đối soát'
                                            }}
                                        </template>
                                        <template #amount="props">
                                            {{
                                               convertAmountTransaction( props.row.amount -
                                                props.row.gateway_fee -
                                                props.row.app_fee)
                                            }}đ
                                        </template>
                                    </v-client-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import actions from '../../../store/moduleCloud/actions';
    import FinanceTab from '../../../components/layout/financeTab.vue'
    import moment from 'moment';

    const store = useStore();
    const columns = ref([
        // 'createdAt',
        'parner_transaction_code',
        'status',
        'amount',
        'app_fee',
    ]);
    const items = ref([]);
    const isCheck = ref(1);
    const table_option = ref({
        perPage: 10,
        filterable: 0,
        headings: {
            // createdAt: 'Ngày đối soát',
            parner_transaction_code: 'Mã đối soát',
            status: 'Trạng thái đối soát',
            amount: 'Doanh thu đối soát',
            app_fee: 'Phí nền tảng',
        },
        perPageValues: [],
        // skin: 'table table-hover',
        // columnsClasses: { actions: 'actions text-center' },
        // sortable: [],
        // pagination: { nav: 'scroll', chunk: 5 },
        // texts: {
        //     count: 'Showing {from} to {to} of {count}',
        //     filter: '',
        //     filterPlaceholder: 'Search...',
        //     limit: 'Results:',
        // },
        // sortIcon: {
        //     base: 'sort-icon-none',
        //     up: 'sort-icon-asc',
        //     down: 'sort-icon-desc',
        // },
        // filterable: [],
        // resizableColumns: false,
    });

    onMounted(() => {
        getData({ status: isCheck.value });
    });

    const getData = async ({ status }) => {
        try {
            store.commit('SHOW_LOADING', true);
            const res = await actions.getReconciliation(null, { status });
            items.value = res.data.data;
            store.commit('SHOW_LOADING', false);
        } catch (_) {}
    };

    const convertAmountTransaction = (x) => {
        if (!x) return '0';
        return parseInt(x).toLocaleString('vi-VN');
    };

    watch(isCheck, (value) => {
        getData({ status: value });
    });
</script>
<style scoped></style>
