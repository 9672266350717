import { axiosInstance } from '@/helpers/axiosInstance';
import router from '@/router';
import { dispatch } from 'v-tables-3/compiled/mixins/methods';

const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
};
export default {
    async login({ commit, dispatch }, data) {
        try {
            return await axiosInstance
                .post('/api/auth/login', data, {
                    headers,
                })
                .then(async (res) => {
                    console.log('res', res);
                    if (res.data.result) {
                        let token = res.data.token.access_token;
                        // // SET TOKEN
                        sessionStorage.setItem('accessToken', token);
                        sessionStorage.setItem('accessToken', token);
                        sessionStorage.setItem('isLogin', '1');
                        sessionStorage.setItem('userData', JSON.stringify(res.data.userData));
                        // save info by getMe
                        // await dispatch('getMe',token);
                        // await dispatch('getSecretStripe',token);
                    }
                    return res;
                })
                .catch((error) => {
                    return false;
                });
        } catch (error) {
            console.log(error);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Login fail, please try again !',
            });
            return false;
        }
    },
    async getMe({ commit }, token) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            // return await axiosInstance.get('/api/v1/auth/me', {headers})
            //     .then(async response => {
            //         let result = response.data.result
            //         sessionStorage.setItem('isLogin', '1')
            //         sessionStorage.setItem('me', JSON.stringify(result))
            //         // set icon language
            //         switch(result.region_code) {
            //             case 'USD':
            //             case 'EURO':
            //                 localStorage.setItem('i18n_locale','en')
            //                 break;
            //             case 'VN':
            //                 localStorage.setItem('i18n_locale','vi')
            //                 break;
            //             case 'AUD':
            //                 localStorage.setItem('i18n_locale','aud')
            //                 break;
            //             default:
            //             // code block
            //         }
            //         commit('SET_ME',JSON.stringify(result))
            //         return response
            //     })
            //     .catch(error => {
            //         console.log(error)
            //         return false
            //     })
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async setLocateLanguage(code) {
        console.log('setLocateLanguage', code);
        switch (code) {
            case 'USD':
            case 'EURO':
                localStorage.setItem('i18n_locale', 'en');
                break;
            case 'VN':
                localStorage.setItem('i18n_locale', 'vi');
                break;
            default:
            // code block
        }
    },
    async registerNew({ commit, dispatch }, data) {
        try {
            const headers = { 'Content-Type': 'application/json' };
            return await axiosInstance
                .post('/api/auth/register', data, {
                    headers,
                })
                .then(async (res) => {
                    if (res.data.error) {
                        dispatch('showNotification', {
                            title: 'Error',
                            type: 'error',
                            text: res.data.error.phone_number,
                        });
                    } else {
                        dispatch('showNotification', {
                            title: `Success !`,
                            type: 'success',
                            text: `Create account success,now confirm your phone number !`,
                        });
                    }

                    // await router.push({name: 'login', params: {email: data.email, password: data.password}})
                    return res;
                })
                .catch((error) => {
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: error.response.data.message,
                    });
                    console.log(error);
                    return false;
                });
        } catch (error) {
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: error.response.data.message,
            });
            console.log(error);
            return false;
        }
    },
    async checkOTPPhone({ commit, dispatch }, data) {
        try {
            const headers = { 'Content-Type': 'application/json' };
            return await axiosInstance
                .post('/api/auth/confirm-otp', data, {
                    headers,
                })
                .then(async (res) => {
                    console.log('res confirm', res);
                    return res;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async resendOTPAPI({ commit, dispatch }, data) {
        try {
            const headers = { 'Content-Type': 'application/json' };
            return await axiosInstance
                .post('/api/auth/send-otp', data, {
                    headers,
                })
                .then(async (res) => {
                    console.log('res resendOTPAPI', res);
                    dispatch('showNotification', {
                        title: `Success !`,
                        type: 'success',
                        text: `OTP sent your phone number !`,
                    });
                    return res;
                })
                .catch((error) => {
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: error.response.data.message,
                    });
                    console.log(error);
                    return false;
                });
        } catch (error) {
            return false;
        }
    },
    async getWpLogSocket({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/wp/${id}/log`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getTransactions({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/payments/stripe`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getListMethod({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/payments/stripe/methods`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getInvoices({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/payments/stripe/invoices`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getWordPress({ commit, state }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/wp`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getWordPressID({ commit, state }, id) {
        try {
            const headers = {
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/wp/${id}`, { headers: headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async payment({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .post('/api/payments/checkout', data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch');
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async createWP({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .post('/api/wp', data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async updateDiskWP({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/wp/${data.id}/disk`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async updateDomainWP({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/wp/${data.id}/domain`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async updateSizeWPAPI({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/wp/${data.id}`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async getTransID({ commit }, transId) {
        try {
            let token =
                sessionStorage.getItem('accessToken') != null
                    ? sessionStorage.getItem('accessToken')
                    : localStorage.getItem('accessToken');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            return await axiosInstance
                .get(`/api/payments/get-transaction?transactionCode=${transId}`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getSecretStripe({ commit }, token) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/v1/auth/card', { headers })
                .then((response) => {
                    let result = response.data.result;
                    // console.log('rs getSecretStripe', result)
                    sessionStorage.setItem('clientStripe', result.client_secret);
                    // commit('SET_ME',JSON.stringify(result))
                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getDNSList({ commit, state }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/dns`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async createDNS({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .post('/api/dns', data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async getDetailDNS({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/dns/${id}`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getDomainRecords({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/dns/${id}/records`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async checkoutCreditCardAPI({ commit, state }, amount) {
        commit('SHOW_LOADING', true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .post(`/api/payments/stripe/checkout`, amount, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async attachMethodAPI({ commit, dispatch }, methodId) {
        commit('SHOW_LOADING', true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .put(`/api/payments/stripe/attach`, { methodId: methodId }, { headers })
                .then((response) => {
                    commit('SHOW_LOADING', false);
                    dispatch('showNotification', {
                        title: `Success !`,
                        type: 'success',
                        text: 'Attached method payment !',
                    });
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    commit('SHOW_LOADING', false);
                    dispatch('showNotification', {
                        title: `Error !`,
                        type: 'error',
                        text: `Can not attach method payment !`,
                    });
                    return false;
                });
        } catch (error) {
            commit('SHOW_LOADING', false);
            console.log(error);
            dispatch('showNotification', {
                title: `Error !`,
                type: 'error',
                text: `Can not attach method payment !`,
            });
            return false;
        }
    },
    async confirmCreditCardAPI({ commit, state }, data) {
        commit('SHOW_LOADING', true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .post(
                    `/api/payments/stripe/${data.transId}/confirm`,
                    { method_id: data.methodId },
                    { headers }
                )
                .then((response) => {
                    commit('SHOW_LOADING', false);
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    commit('SHOW_LOADING', false);
                    return false;
                });
        } catch (error) {
            commit('SHOW_LOADING', false);
            console.log(error);
            return false;
        }
    },
    async unLockAPI({ commit, dispatch }, data) {
        try {
            return await axiosInstance
                .post('/api/auth/login', data, {
                    headers,
                })
                .then(async (res) => {
                    return res;
                })
                .catch((error) => {
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Password invalid, please try again !',
                    });
                    return false;
                });
        } catch (error) {
            console.log(error);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Password invalid, please try again !',
            });
            return false;
        }
    },
    async DNScheck({ commit, state }, domain) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/dns/check?domain=${domain}`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);

                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async addRecordToDomain({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .post(`/api/dns/${data.id}/records`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Fail to add record to domain !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Fail to add record to domain !',
            });
            return false;
        }
    },

    async deleteDNSAPI({ commit, dispatch }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .delete(`/api/dns/${id}`, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async getBanks() {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/users/bank', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getWithDrawFee() {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('api/users/withdraw-fee', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getReconciliation(_, { status }) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.get(`/api/transaction/filter?status=${status}`, { headers });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getProducts() {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.get(`/api/products`, { headers });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async updateProducts(_, productId, priceId) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.put(
                `/api/users/products`,
                { product_id: productId, price_id: priceId },
                { headers }
            );
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async updatePaymentMethod(id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.post(`/api/users/${id}/payment-method`, {}, { headers });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getInvoice() {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.get(`/api/users/invoice`, { headers });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getInvoiceDetail({ commit }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.get(`/api/users/invoice/${id}`, { headers });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async postSubscriptions({ code, defaultPaymentMethod }) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.post(
                `/api/users/subscriptions`,
                { code, default_payment_method: defaultPaymentMethod },
                { headers }
            );
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getBankInfo({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/banks_info', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getBalance({ commit, state }, {}) {
        try {
            commit('SHOW_LOADING', true);
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/users/balance', { headers })
                .then((response) => {
                    commit('SHOW_LOADING', false);
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    commit('SHOW_LOADING', false);
                    return false;
                });
        } catch (error) {
            commit('SHOW_LOADING', false);
            console.log(error);
            return false;
        }
    },
    async getDonateInfo({ commit, state }, slug) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/donate/' + slug, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async rutTienAPI({ commit }, data) {
        commit('SHOW_LOADING', true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .post('/api/users/balance/payout', data, { headers })
                .then((response) => {
                    commit('SHOW_LOADING', false);
                    return response;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            commit('SHOW_LOADING', false);
            console.log(error);
            return false;
        }
    },
    async getBankList({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/banks_info', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async delBanks({ commit, state }, id) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .delete(`/api/banks/${id}`, { headers })
                .then((response) => {
                    window.location.reload();
                    // return response
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getTransactionsHistories({ commit, state }, page) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/transaction-history`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getUserTransactionsHistories(_, limit) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance.get(`/api/users/transaction-histories?limit=${limit}`, {
                headers,
            });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getOrderDonate({ commit, state }, page) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get(`/api/orders`, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getStatisticsToday({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/statistics/today', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async uploadImage({ commit, state }, { formData, slug }) {
        try {
            commit('SHOW_LOADING', true);
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .post(`api/users/upload/${slug}`, formData, { headers })
                .then((response) => {
                    commit('SHOW_LOADING', false);
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    commit('SHOW_LOADING', false);
                    return false;
                });
        } catch (error) {
            console.log(error);
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async getStatisticsLastWeek({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/statistics/last_week', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getStatisticsYesterday({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/statistics/yesterday', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getStatisticsWeek({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/statistics/week', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getStatisticsMonth({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/statistics/month', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getStatisticsLastMonth({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/statistics/last_month', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async addRecordBank({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .post(`/api/banks`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Fail to add record to domain !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Fail to add record to domain !',
            });
            return false;
        }
    },
    async updateRecordBank({ commit, dispatch }, data) {
        try {
            console.log('data', data);
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/banks/${data.id}`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async updateDonateSetting({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`api/widgets/donate`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async donateSettingDetail({ commit, dispatch }) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .get(`/api/donate-setting`, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async updateWidgetTarget({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/widgets/target`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async getWidgetsDonate({ commit }) {
        try {
            commit('SHOW_LOADING', true);
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            const response = await axiosInstance.get('/api/widgets/donate', { headers });
            return response;
        } catch (error) {
            console.log('/api/widgets/donate error', error);
            return false;
        } finally {
            commit('SHOW_LOADING', false);
        }
    },
    async getWidgetsTarget({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/widgets/target', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getWidgetsTop({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .get('/api/widgets/top', { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async getMeDonate({ commit, state }, {}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            return await axiosInstance
                .post('/api/auth/me', null, { headers })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async updateWidgetTop({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/widgets/top`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async updateProfileDonate({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/users`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async updateWidgetTopDay({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/widgets/top_day`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async updateWidgetTopWeek({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/widgets/top_week`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async updateWidgetTopMonth({ commit, dispatch }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            return await axiosInstance
                .put(`/api/widgets/top_month`, data, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    dispatch('showNotification', {
                        title: 'Error',
                        type: 'error',
                        text: 'Không thành công !',
                    });
                    return false;
                });
        } catch (error) {
            console.log('error catch', error);
            commit('SHOW_LOADING', false);
            dispatch('showNotification', {
                title: 'Error',
                type: 'error',
                text: 'Không thành công !',
            });
            return false;
        }
    },
    async paymentDonateATM({ commit, dispatch }, message) {
        try {
            commit('SHOW_LOADING', true);

            const headers = {
                'Content-Type': 'application/json',
            };
            return await axiosInstance
                .post('/api/payments/transaction', message, {
                    headers,
                })
                .then(async (res) => {
                    commit('SHOW_LOADING', false);
                    return res;
                })
                .catch((error) => {
                    commit('SHOW_LOADING', false);
                    console.log(error);
                    return false;
                });
        } catch (error) {
            console.log('error catch');
            commit('SHOW_LOADING', false);
            return false;
        }
    },
    async testNotifyAction({ commit }, data) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            };
            commit('SHOW_LOADING', true);
            const res = await axiosInstance.post('/api/widgets/donate-test', data, {
                headers,
            });
            commit('SHOW_LOADING', false);
            return res;
        } catch (error) {
            console.log('error catch');
            commit('SHOW_LOADING', false);
            return false;
        }
    },
};
