<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Trang chủ</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Mục tiêu</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="card">
                                    <div class="card-header">
                                        <b style="color: #0a0d26">Mục tiêu</b>
                                    </div>
                                    <div class="row card-body d-flex">
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Url hiển thị
                                                <button @click="refreshToken()" class="btn btn-secondary"> <i class="px-2 fa-solid fa-arrows-rotate"></i>Làm mới</button>
                                            </label>
                                            <div>
                                                <input v-model="url" type="text" class="form-control"/>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Mục tiêu</label>
                                            <div>
                                                <input v-model="max" type="text" class="form-control"/>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Tiêu đề</label>
                                            <div>
                                                <input v-model="title" type="text" class="form-control"/>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <label class="col-form-label">Không hiển thị khi đạt mục tiêu</label>
                                            <div>
                                            <label class="switch s-icons s-outline s-outline-secondary me-2">
                                                <input v-model="hidden_done" type="checkbox"/>
                                                <span class="slider"></span>
                                            </label>

                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">
                                            <div>
                                                <button type="button" @click="updateTarget" class="btn btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';

import {useMeta} from '@/composables/use-meta';

useMeta({title: 'Mục tiêu'});
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'

export default {
    name: "Target",
    data() {
        return {
            domainDetail: null,
            name: this.$route.query.full_name,
            type: "A",
            content: "",
            ttl: "",
            proxied: true,
            number: this.$route.query.number_id,
            bankid: this.$route.query.bank_id,
            address: this.$route.query.branch,
            bankList: [],

            hidden_done: false,
            max: 0,
            status: false,
            title: '',
            value: 0,
            url: 'https://donate.episcloud.com/screens/target-progress/',

        }
    },
    mounted() {
        $(".modal-backdrop").remove()

    },
    created() {
        this.getWidgetsTarget({}).then(res => {
            //this.bankList = res.data.data
            this.url = this.url + res.data.token
            this.value = res.data.json.max
            this.title = res.data.json.title
            this.hidden_done = res.data.json.hidden_done
            this.max = res.data.json.max
        }).catch(e => {
            console.log(e)
        })
    },
    computed: {
        ...mapGetters(['getterMe', 'getterCurrency']),
    },
    methods: {
        ...mapActions(['getDetailDNS', 'updateWidgetTarget', 'getWidgetsTarget']),
        refreshToken(){
            let obj = {
                token: true,
            }
            this.updateWidgetTarget(obj).then(res => {
                console.log('updateDonateSetting', res)
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.url = 'https://donate.episcloud.com/screens/target-progress/' + res.data.data
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        },
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date) {
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        updateTarget() {
            let obj = {
                hidden_done: this.hidden_done,
                max: this.max,
                status: this.status,
                title: this.title,
                value: this.value,
            }
            this.updateWidgetTarget(obj).then(res => {
                console.log('updateWidgetTarget', res)
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}

.panel {
    border: none !important;
    background-color: unset !important;
}
</style>
