<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Trang chủ</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Đổi mật khẩu</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="card">
                                    <div class="card-header">
                                        <b style="color: #0a0d26">Đổi mật khẩu</b>
                                    </div>
                                    <div class="row col-12">
                                        <div class="row col-lg-6 col-12 card-body d-flex">
                                            <div class="mb-4 col-12">Mật khẩu hiện tại
                                                <div class="d-flex">
                                                    <input v-model="oldPW" type="password" class="form-control"/>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">Nhập mật khẩu mới
                                                <div class="d-flex">
                                                    <input v-model="newPW" type="password" class="form-control"/>
                                                </div>
                                            </div>
                                            <div class="mb-4 col-12">
                                                <div>
                                                    <button type="button" @click="updatePW" class="btn btn-primary">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';

import {useMeta} from '@/composables/use-meta';

useMeta({title: 'Đổi mật khẩu'});
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'

export default {
    name: "ChangePassword",
    data() {
        return {
            oldPW:'',
            newPW:'',
        }
    },
    mounted() {
    },
    created() {
        this.getMeDonate({}).then(res => {
            // console.log(res)
            let info = res.data.user
            this.info = info
            this.slug = info.slug
            this.email = info.email
            this.name = info.name
            this.youtube_url = info.youtube_url
            this.designation = info.designation
            this.facebook_url = info.facebook_url
            this.nimotv = info.nimotv
        }).catch(e => {
            console.log(e)
        })
    },
    computed: {
        ...mapGetters(['getterCurrency']),
    },
    methods: {
        ...mapActions(['getMeDonate','updateProfileDonate']),
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date) {
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        updatePW() {
            let obj = {
                RetypePassword: this.newPW,
                newPasswordValue: this.newPW,
                passwordValueOld: this.oldPW,
                type:'change_password'
            }
            this.updateProfileDonate(obj).then(res => {
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}

.panel {
    border: none !important;
    background-color: unset !important;
}
</style>
