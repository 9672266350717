<template>
    <div class="layout-px-spacing">
        <div class="row layout-spacing mt-4">
            <div class="d-flex justify-content-center mb-2">
                <button
                    type="button"
                    class="btn mx-2"
                    :class="activeButton === 0 ? 'btn-primary' : 'btn-outline-primary'"
                    @click="onChangeActiveTab(0)"
                >
                    Donate
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary mx-2"
                    :class="activeButton === 1 ? 'btn-primary' : 'btn-outline-primary'"
                    @click="onChangeActiveTab(1)"
                >
                    Mua sắm
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary mx-2"
                    :class="activeButton === 2 ? 'btn-primary' : 'btn-outline-primary'"
                    @click="onChangeActiveTab(2)"
                >
                    Top donate
                </button>
            </div>
            <div class="col-lg-8 col-12 layout-top-spacing">
                <div class="bio layout-spacing">
                    <div v-if="activeButton === 0">
                        <div class="bio-skill-box pt-0 price-box">
                            <div class="panel">
                                <div class="panel-body">
                                    <div class="bio-skill-box pt-0 price-box">
                                        <div>
                                            <p style="font-size: 17px; font-weight: bold">
                                                Thông tin donate
                                            </p>
                                        </div>
                                        <div class="row mt-4">
                                            <form action="">
                                                <div class="form-group">
                                                    <label>Họ tên</label>
                                                    <input
                                                        id="fullName"
                                                        type="text"
                                                        class="form-control"
                                                        v-model="fullName"
                                                        @blur="v$.fullName.$touch"
                                                    />
                                                    <div
                                                        class="invalid-cloud"
                                                        v-if="v$.fullName.$error"
                                                    >
                                                        Tên không được bỏ trống
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Lời nhắn</label>
                                                    <textarea
                                                        id="message"
                                                        row="3"
                                                        class="form-control"
                                                        v-model="message"
                                                        @blur="v$.message.$touch"
                                                    />
                                                    <div
                                                        class="invalid-cloud"
                                                        v-if="v$.message.$error"
                                                    >
                                                        Lời nhắn không được bỏ trống
                                                    </div>
                                                </div>
                                                <div
                                                    class="checkbox-primary custom-control custom-checkbox"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        value="true"
                                                        id="chk1"
                                                    />
                                                    <label class="custom-control-label" for="chk1"
                                                        >Nội dung gửi riêng không hiển thị</label
                                                    >
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel mt-3">
                                <div class="panel-body">
                                    <div>
                                        <p style="font-size: 17px; font-weight: bold">
                                            Số tiền muốn donate
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div
                                            class="deno-item col-12 col-lg-6 mb-lg-4 mb-3 m-auto"
                                            :class="priceActive === 7 ? 'active' : ''"
                                            @click="setPriceActive(7, 0)"
                                        >
                                            <div class="d-flex b-skills" style="padding: 7px">
                                                <div></div>
                                                <div class="text-center position-relative">
                                                    <input
                                                        v-model="amount"
                                                        type="number"
                                                        class="form-control free_price"
                                                        placeholder="________"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            v-for="(item, i) in priceVND"
                                            class="deno-item col-6 col-xl-3 col-lg-3 mb-lg-4 mb-3"
                                            :class="priceActive === i ? 'active' : ''"
                                            @click="setPriceActive(i, item.value)"
                                        >
                                            <div class="d-flex b-skills">
                                                <div></div>
                                                <div class="text-center">
                                                    <h4 class="text-center">
                                                        {{ item.text }}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel" v-else-if="activeButton === 2">
                        <div class="panel-body">
                            <h5>Top Donate</h5>
                            <table class="table mt-4">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Họ tên</th>
                                        <th scope="col">Số tiền</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in top">
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ item.name }}</td>
                                        <td>{{ convertCurrency(item.amount) }} Đ</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else></div>
                </div>
            </div>
            <div class="col-lg-4 col-12 layout-top-spacing">
                <div class="panel" v-if="fullName !== ''">
                    <div class="panel-body">
                        <h5>{{ fullName }} đã gửi bạn {{ amount }}</h5>
                        <span>{{ message }}</span>
                    </div>
                </div>
            </div>
            <div class="bio" v-if="isValidPaymentDonate && activeButton === 0">
                <div class="col-lg-12 bio-skill-box pt-0 price-box">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="row">
                                <p style="font-size: 17px; font-weight: bold">
                                    Hình thức thanh toán
                                </p>
                                <div
                                    v-for="(item, i) in getListPaymentType"
                                    class="deno-item col-12 col-md-3 mb-lg-4 mb-3"
                                    :class="paymentTypeActive === i ? 'active' : ''"
                                    @click="setPaymentTypeActive(i, item.value)"
                                >
                                    <div class="d-flex b-skills box-pay">
                                        <div></div>
                                        <div class="text-center pay-item">
                                            <h4 class="text-center">
                                                {{ item.text }}
                                            </h4>
                                            <img
                                                :src="require('@/assets/images/' + item.img)"
                                                :style="'width:' + item.width"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4 col-12">
                                <div>
                                    <button
                                        v-if="paymentTypeActive !== 2 && isValidPaymentDonate"
                                        type="button"
                                        class="btn btn-primary"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="modalATM"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <form class="text-start">
                            <div class="form">
                                <p>Họ tên chủ thẻ</p>
                                <div class="input-group mb-4">
                                    <input
                                        v-model="pay_data_ATM.cardHolder"
                                        required
                                        type="text"
                                        class="form-control"
                                        placeholder="NGUYEN VAN A"
                                    />
                                </div>
                                <p>Số thẻ (Gồm 16 kí tự)</p>
                                <div class="input-group mb-4">
                                    <input
                                        v-model="pay_data_ATM.cardNumber"
                                        required
                                        type="text"
                                        class="form-control"
                                        placeholder="9704 xx xxxx xxxx xxxx"
                                    />
                                </div>
                                <p>Ngày hiệu lực (Ví dụ: 03-07)</p>
                                <div class="input-group mb-4">
                                    <input
                                        v-model="pay_data_ATM.issueDate"
                                        required
                                        type="text"
                                        class="form-control"
                                        placeholder="MM-YY"
                                    />
                                </div>

                                <button
                                    @click="payATM"
                                    type="button"
                                    class="btn btn-primary w-100 p-2"
                                >
                                    Tiếp tục
                                </button>
                                <button
                                    @click="hideFormATM"
                                    type="button"
                                    class="btn btn-danger w-100 p-2 mt-2"
                                >
                                    Huỷ
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal"
            id="formNapas"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-body" id="form-ct-napas">
                    <iframe :srcdoc="srcDoc"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import $ from 'jquery';
    import { pub } from '@/helpers/pub';
    import useVuelidate from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import '@/assets/sass/scrollspyNav.scss';
    import '@/assets/sass/users/user-profile.scss';

    var RSA = require('node-crypto-js').RSA;
    var Crypt = require('node-crypto-js').Crypt;
    var modalATM;
    export default {
        name: 'Payment',
        setup() {
            return {
                v$: useVuelidate(),
            };
        },
        data() {
            return {
                activeButton: 0,
                top: [],
                paymentTypeIndex: 0,
                loading: false,
                amount: 0,
                priceActive: -1,
                paymentTypeActive: -1,
                showFormInputPayment: false,
                paymentMethod: 1,
                paymentType: [
                    {
                        text: 'Alepay',
                        value: 2,
                        img: 'alego2.png',
                        width: '100px',
                    },
                    {
                        text: 'VietQR',
                        value: 3,
                        img: 'vietqr2.png',
                        width: '102px',
                    },
                    {
                        text: 'ATM Nội địa',
                        value: 4,
                        img: 'debit-card-icon-10.jpg',
                        width: '56px',
                    },
                    {
                        text: 'Thẻ quốc tế',
                        value: 5,
                        img: 'credit-card.png',
                        width: '58px',
                    },
                    {
                        text: 'VN Pay',
                        value: 6,
                        img: 'vnpay.png',
                        width: '52px',
                    },
                    {
                        text: 'Credit Card',
                        value: 7,
                        img: 'epispayment.png',
                        width: '94px',
                    },
                ],
                methodLinkCard: null,
                priceVND: [
                    {
                        text: '10.000đ',
                        value: 10000,
                    },
                    {
                        text: '20.000đ',
                        value: 20000,
                    },
                    {
                        text: '50.000đ',
                        value: 50000,
                    },
                    {
                        text: '100.000đ',
                        value: 100000,
                    },
                ],
                modalPayMe: null,
                checkoutURLPayMe: '',
                showFormStripe: false,
                showFormPayme: false,
                methodIDStripe: '',
                isChooseCreditCard: false,
                pay_data_ATM: {
                    cardNumber: '',
                    issueDate: '',
                    cardHolder: '',
                },
                msg: [],
                fullName: '',
                user_id: '',
                message: '',
                srcDoc: '',
            };
        },
        validations() {
            return {
                fullName: { required },
                message: { required },
            };
        },
        created() {
            this.getDonateInfo(this.$route.params.slug).then((r) => {
                this.top = r.data.tops;
                this.user_id = r.data.data.id;
            });
        },
        mounted() {
            this.$ga.page(this.$options.name);
        },
        computed: {
            ...mapGetters([]),
            listPrice() {
                return this.priceVND;
            },
            getListPaymentType() {
                return this.paymentType.filter(function (element) {
                    return element.value < 7;
                });
            },
            isValidPaymentDonate() {
                return this.fullName === '' || this.message === '' || this.amount === 0
                    ? false
                    : true;
            },
        },
        methods: {
            ...mapActions(['attachMethodAPI', 'getDonateInfo', 'paymentDonateATM']),
            onChangeActiveTab(btnIndex) {
                this.activeButton = btnIndex;
            },
            convertAmountTransaction(x) {
                return parseInt(x).toLocaleString('vi-VN');
            },
            convertCurrency(x) {
                return x.toLocaleString('vi-VN');
            },
            setPriceActive(index, value) {
                console.log('value,', value);
                console.log('index,', index);
                this.priceActive = index;
                this.amount = value;
            },
            setPaymentTypeActive(index, value) {
                this.showFormStripe = false;
                this.paymentTypeActive = index;
                this.showFormInputPayment = false;
                this.paymentMethod = value;

                console.log(index);
                if (index === 5) {
                    this.showFormInputPayment = true;
                } else if (index === 2) {
                    modalATM = new bootstrap.Modal(document.getElementById('modalATM'), {
                        keyboard: true,
                    });
                    modalATM.show();
                }
            },
            hideFormATM() {
                modalATM.hide();
            },
            formatCreatedAt(date) {
                date = new Date(date);
                let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return (
                    date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s
                );
            },
            payCreditCard() {
                this.showFormStripe = true;
                this.showFormPayme = false;
            },

            checkout() {
                this.loading = true;
                let orderType = 2;
                let orderMethod = null;
                // ================= ALEPAY , type = 1, còn lại type = 2
                if (this.paymentMethod == 2) {
                    orderType = 1;
                }
                if (this.paymentMethod == 3) {
                    orderMethod = 'VIETQR';
                }
                if (this.paymentMethod == 4) {
                    orderMethod = 'ATMCARD';
                }
                if (this.paymentMethod == 5) {
                    orderMethod = 'CREDITCARD';
                }
                if (this.paymentMethod == 6) {
                    orderMethod = 'QRPAY';
                }
                if (this.paymentMethod == 7) {
                    orderMethod = 'BANKTRANSFER';
                }
                const data = {
                    amount: parseInt($('#price_payment').val()),
                    type: orderType,
                    method: orderMethod,
                };

                this.payment(data)
                    .then((res) => {
                        console.log('res payment', res);
                        this.loading = false;
                        if (res.data.statusCode == 200 && res.data.message === 'success') {
                            let url = res.data.result.checkoutUrl;
                            if (url.includes('http:')) {
                                url = url.replace('http:', 'https:');
                            }
                            window.location.href = url;
                        } else {
                            this.$store.dispatch('showNotification', {
                                title: `Error  !`,
                                type: 'error',
                                text: `Please try again !`,
                            });
                        }
                    })
                    .catch((e) => {
                        console.log('e', e);
                        this.loading = false;
                        this.$store.dispatch('showNotification', {
                            title: `Error !`,
                            type: 'error',
                            text: `Please try again !`,
                        });
                    });
            },
            setpaymentTypeIndex(i) {
                this.paymentTypeIndex = i;
            },
            payATM() {
                var crypt = new Crypt();
                var rsa = new RSA();
                // var message = this.donateFormATM;
                let message = {
                    method: 'ATMCARD',
                    amount: this.amount,
                    user: {
                        full_name: $('#fullName').val(),
                    },
                    pay_data: this.pay_data_ATM,
                    user_id: this.user_id,
                    message: $('#message').val(),
                };
                var encrypted = crypt.encrypt(pub, JSON.stringify(message));

                this.paymentDonateATM({ message: encrypted })
                    .then((res) => {
                        console.log('res paymentDonateATM', res);
                        modalATM.hide();
                        var modalNapas = new bootstrap.Modal(document.getElementById('formNapas'), {
                            keyboard: true,
                        });
                        $('#form-ct-napas').html(res.data.data.form);
                        modalNapas.show();
                        $('#napasModalDialog').css('max-width', '100%');
                    })
                    .catch((e) => {
                        console.log('e', e);
                        this.$store.dispatch('showNotification', {
                            title: `Error !`,
                            type: 'error',
                            text: `Please try again !`,
                        });
                    });
            },
        },
    };
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .icon-credit-card {
        width: 400px;
    }

    .box-payment-card {
        margin: auto;
    }

    .credit-card-item {
        width: 400px;
        cursor: pointer;
    }

    .credit-card-item.active {
        border: 2px solid #3d38e1;
    }

    .btn-checkout {
        height: 55px;
        font-size: 18px;
        margin-top: 30px;
        width: 45%;
    }

    .bio .b-skills {
        padding: 20px;
        cursor: pointer;
        justify-content: center;
    }

    .bio .b-skills h4 {
        font-weight: bold;
        margin-bottom: 0;
    }

    .deno-item.active .b-skills {
        background: #4361ee;
        transition: transform 2s;
    }

    .deno-item.active h4 {
        color: white;
    }

    .free_price {
        border: none;
        width: 174px;
        box-shadow: none !important;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        color: #3b3f5c;
    }

    .deno-item.active .free_price {
        background-color: #4361ee;
        color: white;
    }

    #currency {
        position: absolute;
        top: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        right: 0;
        color: #3b3f5c;
    }

    .deno-item.active #currency {
        color: white;
    }

    .deno-item.active ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
    }

    ::selection {
        background: none !important;
        color: unset !important;
    }

    .pay-item {
        width: 100%;
        display: flex;
        padding: 20px 60px;
        justify-content: space-between;
        align-items: center;
        max-height: 75px;
    }

    .pay-item img {
        width: 100px;
    }

    .div-input-checkout {
        margin: auto;
        text-align: center;
        width: 100%;
    }

    @media (max-width: 769px) {
        .icon-credit-card {
            width: 100%;
        }

        .price-box .bio .b-skills {
            padding: 10px;
            cursor: pointer;
            justify-content: center;
        }

        .payment-type-box .pay-item {
            padding: 0 20px;
        }

        .pay-item {
            max-height: 42px;
        }

        .btn-checkout {
            height: 45px;
            font-size: 16px;
            margin-top: 10px;
            width: 100%;
        }

        .div-input-checkout {
            width: 100%;
        }

        .box-payment-card {
            width: 100%;
        }
    }

    @media (max-width: 450px) {
        .pay-item {
            max-height: 20px;
        }

        .payment-type-box .pay-item {
            max-height: 30px !important;
        }

        .price-box .b-skills {
            padding: 10px;
        }

        .free_price {
            padding: 3px 0;
        }

        #currency {
            display: none;
        }

        .price-box h4 {
            font-size: 16px;
        }

        .price-box .deno-item .free_price {
            font-size: 16px !important;
            width: 150px;
        }

        .payment-type-box h4 {
            font-size: 16px;
        }
    }
</style>
