<template>
    <Stepper />
</template>
<script>
    import Stepper from '../../components/stepper.vue';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Plans',
        components: {
            Stepper,
        },
    });
</script>
<style></style>
