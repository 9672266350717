<template>
    <div class="form full-form auth-cover">
        <div class="form-container">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <div class="d-flex user-meta">
                                <img :src="avatar" class="usr-profile" alt="avatar" />
                                <div class="">
                                    <p class="">{{ name }}</p>
                                </div>
                            </div>

                            <form class="text-start">
                                <div class="form">
                                    <div id="password-field" class="field-wrapper input mb-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect
                                                x="3"
                                                y="11"
                                                width="18"
                                                height="11"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input
                                            v-model="password"
                                            :type="isShowPW ? 'text' : 'password'"
                                            class="form-control"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div
                                            class="field-wrapper toggle-pass d-flex align-items-center"
                                        >
                                            <p class="d-inline-block">Show Password</p>
                                            <label class="switch s-primary mx-2">
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    :checked="isShowPW"
                                                    @change="onShowPassword"
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="field-wrapper">
                                            <button
                                                type="button"
                                                @click="unLock()"
                                                class="btn btn-primary"
                                            >
                                                Unlock
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <p class="terms-conditions">
                                © {{getCurrentYear}} All Rights Reserved.
                                <router-link to="/">E Cloud</router-link>
                                is a product of EPIS Cloud Inc.
                                <a href="javascript:void(0);">Cookie Preferences</a>,
                                <a href="javascript:void(0);">Privacy</a>, and
                                <a href="javascript:void(0);">Terms</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-image">
                <div class="l-image"></div>
            </div>
        </div>
    </div>
</template>

<!-- <script setup>
    import '@/assets/sass/authentication/auth.scss';

    import { useMeta } from '@/composables/use-meta';

    useMeta({ title: 'Lockscreen Cover' });
</script> -->
<script>
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'login_boxed',
        data() {
            return {
                password: '',
                isShowPW: false,
                name: '',
                avatar: '',
            };
        },
        created() {
            const name = JSON.parse(sessionStorage.getItem('userData')).name;
            const avatar = JSON.parse(sessionStorage.getItem('userData')).avatar;

            this.name = name;
            this.avatar = avatar;
        },
        computed: {
            getCurrentYear() {
                return  moment().year()
            },
            ...mapGetters(['getterMe']),
        },
        methods: {
            ...mapActions(['unLockAPI']),
            onShowPassword(e) {
                this.isShowPW = e.target.checked;
            },
            unLock() {
                let email = JSON.parse(sessionStorage.getItem('userData')).email;

                let obj = { email: email, password: this.password };
                this.unLockAPI(obj)
                    .then(async (res) => {
                        console.log(res.data.result);
                        if (res && res.data.result) {
                            sessionStorage.removeItem('lock_screen');
                            this.$store.commit('SET_LOCK_SCREEN', false);
                            window.location.reload();
                        } else {
                            return false;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        return false;
                    });
            },
        },
    };
</script>
