<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <span>Transaction</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <FinanceTab />
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="row">
                                    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>Transaction Logs</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table
                                        role="table"
                                        aria-busy="false"
                                        aria-colcount="5"
                                        class="table b-table table-bordered"
                                        id="__BVID__328"
                                    >
                                        <thead role="rowgroup" class="">
                                            <tr role="row" class="">
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="1"
                                                    class=""
                                                >
                                                    <div>#</div>
                                                </th>
                                               
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Mã giao dịch</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="2"
                                                    class=""
                                                >
                                                    <div>Mệnh giá</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="2"
                                                    class=""
                                                >
                                                    <div>Phí dịch vụ</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Người gửi</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Kiểu hành động</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Lời nhắn</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Thời gian tạo</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                            <tr
                                                v-for="(item, i) in listTransaction"
                                                :key="i"
                                                role="row"
                                                class=""
                                            >
                                                <td aria-colindex="1" role="cell" class="">
                                                    {{ i + 1 }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.parner_transaction_code }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    <p class="">
                                                        {{ convertAmountTransaction(item.amount) }}
                                                        đ
                                                    </p>
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    <p class="">
                                                        {{ convertAmountTransaction(item.app_fee | item.gateway_fee) }}
                                                        đ
                                                    </p>
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.donate_user }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.method }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.message_donate }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ formatCreatedAt(item.createdAt) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from 'vue';
    import FinanceTab from '../../../components/layout/financeTab.vue'
    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Transaction Logs' });

    //table 1
    const columns = ref(['name', 'position', 'office', 'age', 'start_date', 'salary']);
    const items = ref([]);
    const table_option = ref({
        perPage: 10,
        perPageValues: [5, 10, 20, 50],
        skin: 'table',
        pagination: { nav: 'scroll', chunk: 5 },
        texts: {
            count: 'Showing {from} to {to} of {count}',
            filter: '',
            filterPlaceholder: 'Searcdate...',
            limit: 'Results:',
        },
        resizableColumns: false,
        sortable: ['name', 'position', 'office', 'age', 'start_date', 'salary'],
        sortIcon: {
            base: 'sort-icon-none',
            up: 'sort-icon-asc',
            down: 'sort-icon-desc',
        },
        hiddenColumns: [],
    });

    //table 2
    const items1 = ref([]);

    //table 3
    const items2 = ref([]);

    onMounted(() => {
        bind_data();
    });

    const bind_data = () => {};

    const capitalize = (text) => {
        return text
            .replace('_', ' ')
            .replace('-', ' ')
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const show_hide_columns = (column, value) => {
        if (!table_option.value.hiddenColumns.includes(column) && !value) {
            table_option.value.hiddenColumns.push(column);
        } else {
            table_option.value.hiddenColumns = table_option.value.hiddenColumns.filter(
                (d) => d !== column
            );
        }
    };
</script>
<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'Transaction',
        data() {
            return {
                listTransaction: [],
            };
        },
        created() {
            this.getTransactionsHistories(1)
                .then((res) => {
                    this.listTransaction = res.data.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        computed: {
            ...mapGetters(['getterMe', 'getterCurrency']),
        },
        methods: {
            ...mapActions(['getTransactionsHistories']),
            convertAmountTransaction(x) {
                return parseInt(x).toLocaleString('vi-VN');
            },
            convertCurrency(x) {
                return x.toLocaleString('vi-VN');
            },
            formatCreatedAt(date) {
                date = new Date(date);
                let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return (
                    date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s
                );
            },
        },
    };
</script>

<style scoped></style>
