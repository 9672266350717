<template>
    <div class="layout-px-spacing dash_2">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="javascript:;">Dashboard</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-top-spacing">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-statistics">
                    <div class="widget-heading">
                        <h5>Thống kê</h5>
                        <div class="task-action">
                            <div class="dropdown btn-group">
                                <a
                                    href="javascript:;"
                                    id="ddlStatistics"
                                    class="btn dropdown-toggle btn-icon-only"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-more-horizontal"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <ul
                                    class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="ddlStatistics"
                                >
                                    <li><a href="javascript:;" class="dropdown-item">View</a></li>
                                    <li>
                                        <a href="javascript:;" class="dropdown-item">Download</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <img src="https://epispost.com/static/media/no-result.903ca85d.svg" style="width: 150px; height: 150px"/>
                        <span >Comming soon</span>
                    </div>
                    <!-- <div class="widget-content">
                        <div class="row">
                            <div class="col-6">
                                <div class="w-detail">
                                    <p class="w-title">Total Visits</p>
                                    <p class="w-stats">423,964</p>
                                </div>
                                <apex-chart
                                    v-if="total_visit_options"
                                    height="58"
                                    type="line"
                                    :options="total_visit_options"
                                    :series="total_visit_series"
                                ></apex-chart>
                            </div>
                            <div class="col-6">
                                <div class="w-detail">
                                    <p class="w-title">Paid Visits</p>
                                    <p class="w-stats">7,929</p>
                                </div>
                                <apex-chart
                                    v-if="paid_visit_options"
                                    height="58"
                                    type="line"
                                    :options="paid_visit_options"
                                    :series="paid_visit_series"
                                ></apex-chart>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-total-balance">
                    <div class="widget-content">
                        <div class="account-box">
                            <div class="info">
                                <div class="inv-title">
                                    <h5>Số dư thực tế</h5>
                                </div>
                                <div class="inv-balance-info">
                                    <p class="inv-balance">{{ convertCurrency(balance) }} VNĐ</p>
                                </div>
                            </div>
                            <div class="acc-action">
                                <div></div>
                                <a href="/donate/banks">Rút tiền</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-active-log">
                    <div class="widget-heading">
                        <h5>Lịch sử ủng hộ</h5>
                        <div class="task-action">
                            <div class="dropdown btn-group">
                                <a
                                    href="javascript:;"
                                    id="ddlActivity"
                                    class="btn dropdown-toggle btn-icon-only"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-more-horizontal"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <ul
                                    class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="ddlActivity"
                                >
                                    <li>
                                        <router-link class="dropdown-item" to="/donate/transactions">
                                            View All
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content">
                        <div class="w-shadow-top"></div>
                        <perfect-scrollbar class="mt-container mx-auto">
                            <div class="timeline-line">
                                <div v-for="item in transactionHistories" class="item-timeline">
                                    <div class="t-dot">
                                        <div class="t-danger">
                                            <template v-if="item.amount >= 0">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-check"
                                                >
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg>
                                            </template>
                                            <template v-if="item.amount < 0">
                                                <svg
                                                    v-if="item.amount < 0"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-check"
                                                >
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="t-content">
                                        <div class="t-uppercontent">
                                            <h5 v-if="item.amount < 0">
                                                <a href="javscript:void(0);"
                                                    ><span style="color: red">
                                                        {{ convertAmountTransaction(item.amount) }}
                                                        đ</span
                                                    ></a
                                                >
                                                - {{ item.message_donate }}
                                                - {{ item.donate_user }}
                                            </h5>
                                            <h5 v-if="item.amount >= 0">
                                                <a href="javscript:void(0);"
                                                    ><span>
                                                        +{{
                                                            convertAmountTransaction(item.amount)
                                                        }}
                                                        đ</span
                                                    ></a
                                                >
                                                - {{ item.message_donate }}
                                                - {{ item.donate_user }}
                                            </h5>
                                        </div>
                                        <p>{{ formatCreatedAt(item.createdAt) }}</p>
                                    </div>
                                </div>
                            </div>
                        </perfect-scrollbar>
                        <div class="w-shadow-bottom"></div>
                        <div
                            class="modal"
                            id="invoiceTable"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                        >
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div
                                        class="modal-body"
                                        style="background: white; width: 1000px"
                                    >
                                        <Invoice />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import '@/assets/sass/widgets/widgets.scss';
    import { computed, ref } from 'vue';
    import { useStore } from 'vuex';
    import ApexChart from 'vue3-apexcharts';
    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Widgets' });

    const store = useStore();

    // var categoriesChart = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    //Statistics
    const total_visit_series = ref([{ data: [21, 9, 36, 12, 44, 25, 59, 41, 66, 25] }]);
    const total_visit_options = computed(() => {
        const is_dark = store.state.is_dark_mode;
        return {
            chart: {
                sparkline: { enabled: true },
                dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 1,
                    blur: 3,
                    color: '#009688',
                    opacity: 0.7,
                },
            },
            stroke: { curve: 'smooth', width: 2 },
            markers: { size: 0 },
            colors: ['#009688'],
            grid: { padding: { top: 0, bottom: 0, left: 0 } },
            tooltip: {
                theme: is_dark ? 'dark' : 'light',
                x: { show: false },
                y: {
                    title: {
                        formatter: function formatter() {
                            return '';
                        },
                    },
                },
            },
            responsive: [
                {
                    breakPoint: 576,
                    options: {
                        chart: { height: 95 },
                        grid: { padding: { top: 45, bottom: 0, left: 0 } },
                    },
                },
            ],
        };
    });

    const paid_visit_series = ref([{ data: [22, 19, 30, 47, 32, 44, 34, 55, 41, 69] }]);
    const paid_visit_options = computed(() => {
        const is_dark = store.state.is_dark_mode;
        return {
            chart: {
                sparkline: { enabled: true },
                dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 1,
                    blur: 2,
                    color: '#e2a03f',
                    opacity: 0.7,
                },
            },
            stroke: { curve: 'smooth', width: 2 },
            markers: { size: 0 },
            colors: ['#e2a03f'],
            grid: { padding: { top: 0, bottom: 0, left: 0 } },
            tooltip: {
                theme: is_dark ? 'dark' : 'light',
                x: { show: false },
                y: {
                    title: {
                        formatter: function formatter() {
                            return '';
                        },
                    },
                },
            },
            responsive: [
                {
                    breakPoint: 576,
                    options: {
                        chart: { height: 95 },
                        grid: { padding: { top: 35, bottom: 0, left: 0 } },
                    },
                },
            ],
        };
    });

</script>
<script>
    import { mapActions, mapState } from 'vuex';
    import { ref } from 'vue';
    import Invoice from './modules/donate/Invoice.vue';
    import actions from '../store/moduleCloud/actions';
    var modal;
    export default {
        name: 'index2',
        component: {
            Invoice,
        },
        data() {
            return {
                username: '',
                emailAddress: '',
                password: '',
                phone: '',
                balance: 0,
                transactionHistories: [],
                timeIndex: 1,
                listTimeChart: [],
                listTimeValue: [],
                invoiceData: {},
            };
        },
        mounted() {
            modal = new bootstrap.Modal(document.getElementById('invoiceTable'), {
                keyboard: true,
            });
        },
        created() {
            this.getData();

            this.getBalance({}).then((r) => {
                this.balance = r.data.data.balance.ending_balance;
            });
            this.getTransactionsHistories(1).then((r) => {
                this.transactionHistories = r.data.data;
            });
            this.getStatisticsToday({}).then((r) => {
                this.listTimeChart = r.data.data.xAxisData;
                this.listTimeValue = r.data.data.series;
            });
        },
        methods: {
            ...mapActions([
                'getBanks',
                'getTransactionsHistories',
                'getBalance',
                'getStatisticsYesterday',
                'getStatisticsLastMonth',
                'getStatisticsWeek',
                'getStatisticsMonth',
                'getStatisticsToday',
                'getStatisticsLastWeek',
                'getInvoiceDetail',
            ]),
            async getData() {
                try {
                    const res = await actions.getInvoice();
                    const result = res.data.data.data.filter((item) =>
                        item.status === 'void' ? false : !item.paid
                    );
                    if (result.length !== 0) {
                        modal.show();
                    }
                } catch (_) {}
            },
            onRedirectToInvoice(url) {
                location.href = url;
            },
            convertAmountTransaction(x) {
                return parseInt(x).toLocaleString('vi-VN');
            },
            redirectToDrawMoney() {
                this.$router.push('/donate/banks');
            },
            formatCreatedAt(date) {
                date = new Date(date);
                let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return (
                    date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s
                );
            },
            convertCurrency(x) {
                return x !== undefined ? x.toLocaleString('vi-VN') : 0;
            },
            changeTime() {
                switch (parseInt(this.timeIndex)) {
                    case 1:
                        this.getStatisticsToday({}).then((r) => {
                            console.log('getStatisticsToday');
                            this.listTimeChart = r.data.data.xAxisData;
                            this.listTimeValue = r.data.data.series;
                        });
                        break;
                    case 2:
                        this.getStatisticsWeek({}).then((r) => {
                            console.log('getStatisticsWeek');
                            this.listTimeChart = r.data.data.xAxisData;
                            this.listTimeValue = r.data.data.series;
                        });
                        break;
                    case 3:
                        this.getStatisticsMonth({}).then((r) => {
                            console.log('getStatisticsMonth');
                            this.listTimeChart = r.data.data.xAxisData;
                            this.listTimeValue = r.data.data.series;
                        });
                        break;
                    default:
                    case 4:
                        this.getStatisticsYesterday({}).then((r) => {
                            console.log('getStatisticsYesterday');
                            this.listTimeChart = r.data.data.xAxisData;
                            this.listTimeValue = r.data.data.series;
                        });
                        break;
                    case 5:
                        this.getStatisticsLastWeek({}).then((r) => {
                            console.log('getStatisticsLastWeek');
                            this.listTimeChart = r.data.data.xAxisData;
                            this.listTimeValue = r.data.data.series;
                        });
                        break;
                    case 6:
                        this.getStatisticsLastMonth({}).then((r) => {
                            console.log('getStatisticsLastMonth');
                            this.listTimeChart = r.data.data.xAxisData;
                            this.listTimeValue = r.data.data.series;
                        });
                        break;
                    // code block
                }
            },
        },
        computed: {
            ...mapState(['is_dark_mode']),
            unique_visitor_series() {
                return [{ name: 'Value', data: this.listTimeValue }];
            },
            unique_visitor_options() {
                const is_dark = this.$store.state.is_dark_mode;

                var categoriesChart = Object.values(this.listTimeChart);
                // var categoriesChart = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00',
                //     '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '18:00', '19:00',
                //     '20:00', '21:00', '22:00', '23:00']

                console.log('categoriesChart', categoriesChart);
                return {
                    chart: { toolbar: { show: false } },
                    dataLabels: { enabled: false },
                    stroke: { show: true, width: 2, colors: ['transparent'] },
                    colors: ['#5c1ac3', '#ffbb44'],
                    dropShadow: {
                        enabled: true,
                        opacity: 0.3,
                        blur: 1,
                        left: 1,
                        top: 1,
                        color: '#515365',
                    },
                    plotOptions: {
                        bar: { horizontal: false, columnWidth: '55%', borderRadius: 10 },
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                        fontSize: '14px',
                        markers: { width: 12, height: 12 },
                        itemMargin: { horizontal: 0, vertical: 8 },
                    },
                    grid: { borderColor: is_dark ? '#191e3a' : '#e0e6ed' },
                    xaxis: {
                        categories: categoriesChart,
                        axisBorder: { show: true, color: is_dark ? '#3b3f5c' : '#e0e6ed' },
                    },
                    yaxis: {
                        tickAmount: 6,
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: is_dark ? 'dark' : 'light',
                            type: 'vertical',
                            shadeIntensity: 0.3,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 0.8,
                            stops: [0, 100],
                        },
                    },
                    tooltip: {
                        theme: is_dark ? 'dark' : 'light',
                        y: {
                            formatter: function (val) {
                                return val;
                            },
                        },
                    },
                };
            },
        },
    };
</script>
