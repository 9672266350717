<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <span>Banks</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <FinanceTab />
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-heading">
                                <div class="row">
                                    <div
                                        class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-between align-items-center"
                                    >
                                        <h4>
                                            Số dư :
                                            <span class="font-weight-bold" style="font-size: 28px">
                                                {{
                                                    convertAmountTransaction(balance.ending_balance)
                                                }}
                                                Đ</span
                                            >
                                        </h4>
                                        <div>
                                            <button
                                                type="button"
                                                class="btn btn-secondary mx-2"
                                                data-bs-toggle="modal"
                                                data-bs-target="#fadeinModal"
                                            >
                                                Tạo lệnh rút liền
                                            </button>
                                            <router-link
                                                tag="button"
                                                to="/banks/create"
                                                class="btn btn-info"
                                            >
                                                Thêm thông tin ngân hàng
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <table
                                        role="table"
                                        aria-busy="false"
                                        aria-colcount="5"
                                        class="table b-table table-bordered"
                                        id="__BVID__328"
                                    >
                                        <thead role="rowgroup" class="">
                                            <tr role="row" class="">
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="1"
                                                    class=""
                                                >
                                                    <div>#</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Ngân hàng</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div>Số tài khoản</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="2"
                                                    class=""
                                                >
                                                    <div>Tên chủ thẻ</div>
                                                </th>
                                                <th
                                                    role="columnheader"
                                                    scope="col"
                                                    aria-colindex="4"
                                                    class=""
                                                >
                                                    <div></div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                            <tr
                                                v-for="(item, i) in listBanks"
                                                :key="i"
                                                role="row"
                                                class=""
                                            >
                                                <td aria-colindex="1" role="cell" class="">
                                                    {{ i + 1 }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.vietnamese_name }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.number_id }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    {{ item.full_name }}
                                                </td>
                                                <td aria-colindex="2" role="cell" class="">
                                                    <button
                                                        class="btn btn-warning mx-2"
                                                        @click="editBank(item)"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        class="btn btn-danger"
                                                        @click="deleteBank(item.id)"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal animated fadeInDown"
            id="fadeinModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="fadeinModal"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="fadeinModalLabel">Tạo lệnh rút tiền</h5>
                        <button
                            type="button"
                            data-dismiss="modal"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn-close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-hover">
                            <tbody>
                                <div class="form-group">
                                    <label>Số tiền cần rút</label>
                                    <input v-model="amount" type="number" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label>Nhận về tài khoản</label>
                                    <select v-model="bank_id" name="" class="form-control" id="">
                                        <option v-for="(item, i) in listBanks" :value="item.id">
                                            {{ item.number_id }}
                                        </option>
                                    </select>
                                </div>
                                <p class="text-danger">Lưu ý: Số tiền rút tối thiểu là 100.000 đ</p>
                                <input
                                    readonly
                                    v-model="withdrawFee"
                                    type="text"
                                    class="form-control"
                                />
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn"
                            data-dismiss="modal"
                            data-bs-dismiss="modal"
                        >
                            <i class="flaticon-cancel-12"></i> Huỷ
                        </button>
                        <button type="button" class="btn btn-primary" @click="rutTien()">
                            Xác Nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { pub } from '@/helpers/pub';
    import FinanceTab from '../../../components/layout/financeTab.vue'
    const Crypt = require('node-crypto-js').Crypt;
    export default {
        name: 'Transaction',
        components: {
            FinanceTab
        },
        data() {
            return {
                listBanks: [],
                amount: 0,
                bank_id: '',
                balance: 0,
                withdrawFee: '',
            };
        },
        created() {
            this.getBalance({}).then((r) => {
                console.log(r.data);
                this.balance = r.data.data.balance;
            });
            this.getWithDrawFee().then(
                (e) =>
                    (this.withdrawFee = `Phí rút tiền: ${this.convertAmountTransaction(
                        e.data.metadata.withdraw_fee
                    )}đ`)
            );
            this.getBanks()
                .then((res) => {
                    this.listBanks = res.data.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        computed: {
            ...mapGetters(['getterMe', 'getterCurrency']),
        },
        methods: {
            ...mapActions(['getBanks', 'delBanks', 'getBalance', 'rutTienAPI', 'getWithDrawFee']),
            convertAmountTransaction(x) {
                if (!x) return '0';
                return parseInt(x).toLocaleString('vi-VN');
            },
            convertCurrency(x) {
                return x.toLocaleString('vi-VN');
            },
            deleteBank(id) {
                if (confirm('Xoá thông tin thẻ này !')) {
                    this.delBanks(id);
                }
            },
            editBank(item) {
                let url = `/banks/edit/?id=${item.id}&bank_id=${item.bank_id}&full_name=${item.full_name}&branch=${item.branch}&number_id=${item.number_id}`;
                this.$router.push(url);
            },
            rutTien() {
                if (this.amount < 100000) {
                    {
                        this.$store.dispatch('showNotification', {
                            title: 'Error !',
                            type: 'error',
                            text: 'Số tiền rút phải lớn hơn 100.000 đ !',
                        });
                        return false;
                    }
                }
                const { id } = JSON.parse(sessionStorage.getItem('userData'));
                var crypt = new Crypt();
                const bankId = this.listBanks.filter((e) => e.id === this.bank_id)[0].bank_id;

                const payload = {
                    amount: this.amount,
                    bank_id: bankId,
                    user_id: id,
                };
                const encrypted = crypt.encrypt(pub, JSON.stringify(payload));

                console.log(payload);
                this.rutTienAPI({ message: encrypted })
                    .then((res) => {
                        if (res.data.data.code === 1) {
                            this.$store.dispatch('showNotification', {
                                title: 'Thành công !',
                                type: 'success',
                                text: 'Tạo lệnh rút tiền thành công !',
                            });
                            window.location.reload();
                        } else {
                            this.$store.dispatch('showNotification', {
                                title: 'Error !',
                                type: 'error',
                                text: res.data.error,
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
        },
    };
</script>

<style scoped></style>
