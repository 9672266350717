<template>
    <div class="layout-px-spacing" style="">
        <div class="d-flex justify-content-center" style="margin: 50px 0">
            <h1>Best plans for business</h1>
        </div>
        <div style="display: flex; justify-content: center; align-items: center">
            <div
                v-for="(item, i) in listProduct"
                :style="{
                    width: '300px',
                    height: '500px',
                    position: 'relative',
                    marginTop: '50px',
                    backgroundColor: i === 1 ? 'black' : '#d2d2d2',
                    borderRadius: '30px',
                    marginRight: '50px',
                }"
                v-show="item.active"
            >
                <div
                    :style="{
                        height: '100%',
                        padding: '50px 20px 20px 20px',
                        color: i === 1 ? 'white' : 'black',
                    }"
                    class="d-flex flex-column justify-content-between"
                >
                    <div>
                        <div style="display: flex; justify-content: space-between">
                            <div style="display: flex">
                                <div style="margin-right: 20px">
                                    <div class="social-icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-twitter"
                                        >
                                            <path
                                                d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column">
                                    <span style="font-weight: bold; font-size: 16px">{{
                                        item.name
                                    }}</span>
                                    <span style="font-weight: bold"
                                        >{{
                                            convertAmountTransaction(item.metadata.product_fee)
                                        }}đ</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            style="border: 0.5px solid #b4b4b4; width: 100%; margin: 20px 0px"
                        ></div>
                        <div>
                            <div style="margin-bottom: 15px">
                                <i
                                    class="fa-solid fa-circle-check"
                                    style="color: green; margin-right: 10px"
                                ></i>
                                Phí nền tảng: {{ item.metadata.app_fee }}%
                            </div>
                            <div style="margin-bottom: 15px">
                                <i
                                    class="fa-solid fa-circle-check"
                                    style="color: green; margin-right: 10px"
                                ></i>
                                Phí rút tiền mặt:
                                {{ convertAmountTransaction(item.metadata.withdraw_fee) }}đ
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button
                            type="button"
                            class="btn btn-primary btn-block"
                            @click="() => pay(item.default_price, item.id)"
                        >
                            Đăng ký
                        </button>
                    </div>
                </div>

                <div
                    style="
                        position: absolute;
                        top: -25px;
                        left: 70px;
                        right: 70px;
                        height: 55px;
                        border-bottom-right-radius: 45px 70px;
                        border-bottom-left-radius: 45px 70px;
                        background-color: white;
                        padding: 10px 20px 10px 20px;
                        display: flex;
                        justify-content: center;
                    "
                >
                    <div
                        style="
                            width: 95px;
                            height: 35px;
                            background-color: #de970b;
                            border-radius: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: black;
                            font-weight: bold;
                        "
                    >
                        Special
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent, ref, onBeforeMount, onMounted } from 'vue';
    // import { loadStripe } from '@stripe/stripe-js';
    // import StripeElements from './StripeElements.vue';
    // import StripeElement from './StripeElement.vue';
    import '@/assets/sass/scrollspyNav.scss';
    import '@/assets/sass/users/user-profile.scss';
    import actions from '../store/moduleCloud/actions';
    import { useStore } from 'vuex';

    export default defineComponent({
        name: 'CardOnly',
        setup(_, { emit }) {
            const store = useStore();
            const listProduct = ref([]);

            onMounted(() => {
                getData();
            });

            const getData = async () => {
                try {
                    store.commit('SHOW_LOADING', true);
                    const res = await actions.getProducts();
                    listProduct.value = res.data.data;
                    store.commit('SHOW_LOADING', false);
                } catch (_) {}
            };

            const pay = (defaultPrice, productId) => {
                emit('onChangeStep', { defaultPrice, productId });
            };

            return {
                listProduct,
                pay,
            };
        },
        methods: {
            convertAmountTransaction(x) {
                return parseInt(x).toLocaleString('vi-VN');
            },
        },
    });
</script>
