<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <span>transactions-history</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <FinanceTab />
        <div class="layout-top-spacing d-flex justify-content-between">
            <h4>Lịch sử giao dịch</h4>
            <div>
                <select v-model="limit" class="form-control">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                </select>
            </div>
        </div>
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="table-responsive">
                                    <v-client-table
                                        ref="myTable"
                                        :data="items"
                                        :columns="columns"
                                        :options="table_option"
                                    >
                                        <template #created="props">
                                            {{ formatCreatedAt(props.row.created) }}
                                        </template>
                                        <template #amount="props">
                                            {{ convertAmountTransaction(props.row.amount) }}đ
                                        </template>
                                        <template #ending_balance="props">
                                            {{
                                                convertAmountTransaction(props.row.ending_balance)
                                            }}đ
                                        </template>
                                    </v-client-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import actions from '../../../store/moduleCloud/actions';
    import FinanceTab from '../../../components/layout/financeTab.vue';
    const moment = require('moment');

    const store = useStore();
    const columns = ref(['id', 'amount', 'description', 'ending_balance', 'created']);
    const items = ref([]);
    const limit = ref(10);
    const table_option = ref({
        perPage: 10,
        filterable: 0,
        headings: {
            id: 'Mã giao dịch',
            amount: 'Số tiền',
            description: 'Nội dung',
            ending_balance: 'Số dư cuối',
            created: 'Thời gian tạo',
        },
        perPageValues: [],
        // skin: 'table table-hover',
        // columnsClasses: { actions: 'actions text-center' },
        // sortable: [],
        // pagination: { nav: 'scroll', chunk: 5 },
        // texts: {
        //     count: 'Showing {from} to {to} of {count}',
        //     filter: '',
        //     filterPlaceholder: 'Search...',
        //     limit: 'Results:',
        // },
        // sortIcon: {
        //     base: 'sort-icon-none',
        //     up: 'sort-icon-asc',
        //     down: 'sort-icon-desc',
        // },
        // filterable: [],
        // resizableColumns: false,
    });

    onMounted(() => {
        getData();
    });

    const getData = async () => {
        try {
            store.commit('SHOW_LOADING', true);
            const res = await actions.getUserTransactionsHistories(null, limit.value);
            console.log(res);

            items.value = res.data.data;
            store.commit('SHOW_LOADING', false);
        } catch (_) {
            console.log(_);
        }
    };

    const formatCreatedAt = (date) => {
        date = new Date(date);
        console.log(date);
        let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return hour + ':' + min;
    };
    const convertAmountTransaction = (x) => {
        return parseInt(x).toLocaleString('vi-VN');
    };

    watch(limit, (value) => {
        getData();
    });
</script>

<style></style>
