<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar
                class="list-unstyled menu-categories"
                tag="ul"
                :options="{
                    wheelSpeed: 0.5,
                    swipeEasing: !0,
                    minScrollbarLength: 40,
                    maxScrollbarLength: 300,
                    suppressScrollX: true,
                }"
            >
                <li class="menu">
                    <a
                        style="
                            background: tomato;
                            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
                            border-radius: 6px;
                            color: white;
                        "
                        class="dropdown-toggle"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-video"
                            ></i>
                            <span>Donate Link</span>
                        </div>
                    </a>
                </li>
                <li class="menu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-layout"
                            >
                                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                <line x1="3" y1="9" x2="21" y2="9"></line>
                                <line x1="9" y1="21" x2="9" y2="9"></line>
                            </svg>
                            <span>{{ $t('dashboard') }}</span>
                        </div>
                    </a>
                </li>

                <li class="menu p-3"><b>Tài chính</b></li>
                <li class="menu"></li>
                <li class="menu">
                    <a class="dropdown-toggle">
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-clock-rotate-left"
                            ></i>
                            <span>Lịch sử ủng hộ</span>
                        </div>
                    </a>
                </li>
                <li class="menu">
                    <a class="dropdown-toggle">
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-clock-rotate-left"
                            ></i>
                            <span>Lịch sử giao dịch</span>
                        </div>
                    </a>
                </li>
                <li class="menu">
                    <a
                        class="dropdown-toggle"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-money-bill"
                            ></i>
                            <span>Wallet</span>
                        </div>
                    </a>
                </li>
                <li class="menu">
                    <a
                        class="dropdown-toggle"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-money-check"
                            ></i>
                            <span>Đối soát</span>
                        </div>
                    </a>
                </li>
                <li class="menu">
                    <a
                        class="dropdown-toggle"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-receipt"
                            ></i>
                            <span>Hoá đơn</span>
                        </div>
                    </a>
                </li>
                <li class="menu p-3"><b>Thông báo</b></li>
                <li class="menu">
                    <a
                        class="dropdown-toggle"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-circle-dollar-to-slot"
                            ></i>
                            <span>Donate</span>
                        </div>
                    </a>
                    <a class="dropdown-toggle">
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-bullseye"
                            ></i>
                            <span>Mục tiêu</span>
                        </div>
                    </a>
                    <a class="dropdown-toggle">
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-ranking-star"
                            ></i>
                            <span>Top 5</span>
                        </div>
                    </a>
                </li>

                <li class="menu p-3"><b>Thông tin</b></li>
                <li class="menu">
                    <a
                        class="dropdown-toggle"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-user"
                            ></i>
                            <span>Cập nhật thông tin</span>
                        </div>
                    </a>
                </li>
                <li>
                    <div class="p-3 mt-5" style="background: lightcyan; text-align: center">
                        <p>
                            Nâng gói dịch vụ <br />
                            để được tận hưởng <br />nhiều dịch vụ hơn
                        </p>
                        <button class="btn btn-warning" >Nâng cấp</button>
                    </div>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    const store = useStore();

    onMounted(() => {
        const selector = document.querySelector(
            '#sidebar a[href="' + window.location.pathname + '"]'
        );
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>
<script>
    export default {
        name: 'login_boxed',
        data() {
            return {
                showMenuDashDemo: false,
            };
        },
        created() {},
        computed: {
            getSlug() {
                try {
                    const data = JSON.parse(sessionStorage.getItem('userData'))?.slug;

                    if (data) {
                        return `${process.env.VUE_APP_LANDING_PAGE_URL}/${
                            JSON.parse(sessionStorage.getItem('userData')).slug
                        }`;
                    }
                    return '/pages/error404';
                } catch (error) {}
            },
            checkAdmin() {
                if (JSON.parse(sessionStorage.getItem('userData')) != null) {
                    return (
                        JSON.parse(sessionStorage.getItem('userData')) !== undefined &&
                        JSON.parse(sessionStorage.getItem('userData')).group_id !== undefined &&
                        JSON.parse(sessionStorage.getItem('userData')).group_id.role === 'admin'
                    );
                }
            },
        },
        methods: {
            onOpenModalComingSoon() {
                this.$router.push('/comming-soon');
            },
            goToUpdateInfo() {
                this.$router.push('/update-profile');
            },
        },
    };
</script>
