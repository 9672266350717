<template>
    <div style="margin: 20px 0">
        <div class="d-flex">
            <div class="tab-custom" :class="activeKey === '1' ? 'active' : ''" @click="changeActiveKey('1')">Lịch sử ủng hộ</div>
            <div class="tab-custom" :class="activeKey === '2' ? 'active' : ''" @click="changeActiveKey('2')">Lịch sử giao dịch</div>
            <div class="tab-custom" :class="activeKey === '3' ? 'active' : ''" @click="changeActiveKey('3')">Ví</div>
            <div class="tab-custom" :class="activeKey === '4' ? 'active' : ''" @click="changeActiveKey('4')">Đối soát</div>
            <div class="tab-custom" :class="activeKey === '5' ? 'active' : ''" @click="changeActiveKey('5')">Hoá đơn</div>
        </div>
    </div>
</template>
<script>
    import { defineComponent, ref, onMounted, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    const TabEnum = {
        Transactions: '/donate/transactions',
        TransactionHistory: '/donate/transactions-history',
        Bank: '/donate/banks',
        Reconciliation: '/donate/reconciliation',
        Invoice: '/donate/invoice',
    };

    export default defineComponent({
        setup() {
            const activeKey = ref('1');
            const route = useRoute();
            const router = useRouter();

            onMounted(() => {
                const path = route.fullPath;
                switch (path) {
                    case TabEnum.Transactions:
                        activeKey.value = '1';
                        break;
                    case TabEnum.TransactionHistory:
                        activeKey.value = '2';
                        break;
                    case TabEnum.Bank:
                        activeKey.value = '3';
                        break;
                    case TabEnum.Reconciliation:
                        activeKey.value = '4';
                        break;
                    case TabEnum.Invoice:
                        activeKey.value = '5';
                        break;
                    default:
                        activeKey.value = '1';
                        break;
                }
            });

            watch(activeKey, () => {
                if (activeKey.value === '1') {
                    router.push(TabEnum.Transactions);
                }
                if (activeKey.value === '2') {
                    router.push(TabEnum.TransactionHistory);
                }
                if (activeKey.value === '3') {
                    router.push(TabEnum.Bank);
                }
                if (activeKey.value === '4') {
                    router.push(TabEnum.Reconciliation);
                }
                if (activeKey.value === '5') {
                    router.push(TabEnum.Invoice);
                }
            });

            const changeActiveKey = (key) => {
                activeKey.value = key;
            }

            return {
                activeKey,
                changeActiveKey
            };
        },
    });
</script>
