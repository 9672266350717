<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/">Trang chủ</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Top</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel p-0">
                    <div id="tableProgress" class="col-lg-12 col-12 layout-spacing">
                        <div class="statbox panel box box-shadow">
                            <div class="panel-body">
                                <div class="card">
                                    <div class="card-header">
                                        <b style="color: #0a0d26">Top</b>
                                    </div>
                                    <div class="row card-body d-flex">
                                        <div class="mb-4 col-12">Top 
                                            <div class="d-flex">
                                                <input v-model="url_top" type="text" class="form-control"/>
                                                <button @click="refreshTokenTop()" class="w-25 btn btn-secondary"> <i class="px-2 fa-solid fa-arrows-rotate"></i>Làm mới</button>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">Top Day
                                            <div class="d-flex">
                                                <input v-model="url_day" type="text" class="form-control"/>
                                                <button @click="refreshTokenTopDay()" class="w-25 btn btn-secondary"> <i class="px-2 fa-solid fa-arrows-rotate"></i>Làm mới</button>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">Top Week
                                            <div class="d-flex">
                                                <input v-model="url_week" type="text" class="form-control"/>
                                                <button @click="refreshTokenTopWeek()" class="w-25 btn btn-secondary"> <i class="px-2 fa-solid fa-arrows-rotate"></i>Làm mới</button>
                                            </div>
                                        </div>
                                        <div class="mb-4 col-12">Top Month
                                            <div class="d-flex">
                                                <input v-model="url_month" type="text" class="form-control"/>
                                                <button @click="refreshTokenTopMonth()" class="w-25 btn btn-secondary"> <i class="px-2 fa-solid fa-arrows-rotate"></i>Làm mới</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';

import {useMeta} from '@/composables/use-meta';

useMeta({title: 'Mục tiêu'});
</script>
<script>
import {mapActions, mapGetters} from "vuex";
import $ from 'jquery'

export default {
    name: "Target",
    data() {
        return {
            domainDetail: null,
            name: this.$route.query.full_name,
            type: "A",
            content: "",
            ttl: "",
            proxied: true,
            number: this.$route.query.number_id,
            bankid: this.$route.query.bank_id,
            address: this.$route.query.branch,
            bankList: [],

            hidden_done: false,
            max: 0,
            status: false,
            title: '',
            value: 0,
            
            url_top: 'https://donate.episcloud.com/screens/top/',
            url_day: 'https://donate.episcloud.com/screens/top_day/',
            url_week: 'https://donate.episcloud.com/screens/top_week/',
            url_month: 'https://donate.episcloud.com/screens/top_month/',

        }
    },
    mounted() {
        $(".modal-backdrop").remove()

    },
    created() {
        this.getWidgetsTop({}).then(res => {
            //this.bankList = res.data.data
            this.url_top = this.url_top + res.data[0].token
            this.url_day = this.url_day + res.data[1].token
            this.url_week = this.url_week + res.data[2].token
            this.url_month = this.url_month + res.data[3].token
        }).catch(e => {
            console.log(e)
        })
    },
    computed: {
        ...mapGetters(['getterMe', 'getterCurrency']),
    },
    methods: {
        ...mapActions(['getDetailDNS', 'updateWidgetTop', 'getWidgetsTop','updateWidgetTopDay','updateWidgetTopWeek','updateWidgetTopMonth']),
        refreshTokenTop(){
            let obj = {
                token: true,
            }
            this.updateWidgetTop(obj).then(res => {
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.url_top = 'https://donate.episcloud.com/screens/top/' + res.data.data
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        },
        refreshTokenTopDay(){
            let obj = {
                token: true,
            }
            this.updateWidgetTopDay(obj).then(res => {
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.url_day = 'https://donate.episcloud.com/screens/top_day/' + res.data.data
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        },
        refreshTokenTopWeek(){
            let obj = {
                token: true,
            }
            this.updateWidgetTopWeek(obj).then(res => {
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.url_week = 'https://donate.episcloud.com/screens/top_week/' + res.data.data
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        },
        refreshTokenTopMonth(){
            let obj = {
                token: true,
            }
            this.updateWidgetTopMonth(obj).then(res => {
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.url_month = 'https://donate.episcloud.com/screens/top_month/' + res.data.data
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        },
        convertAmountTransaction(x) {
            return parseInt(x).toLocaleString('vi-VN')
        },
        convertCurrency(x) {
            return x.toLocaleString('vi-VN')
        },
        formatCreatedAt(date) {
            date = new Date(date);
            let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
        },
        updateTarget() {
            let obj = {
                hidden_done: this.hidden_done,
                max: this.max,
                status: this.status,
                title: this.title,
                value: this.value,
            }
            this.updateWidgetTop(obj).then(res => {
                console.log('updateWidgetTop', res)
                if (!res.data.result) {
                    this.$store.dispatch('showNotification', {
                        title: 'Error !',
                        type: 'error',
                        text: res.data.error,
                    })
                    return false;
                } else {
                    this.$store.dispatch('showNotification', {
                        title: 'Success !',
                        type: 'success',
                        text: 'Cập nhật thành công !',
                    })
                }
            }).catch(e => {
                console.log(e)
                return false
            })
        }
    },
}
</script>

<style scoped>
.table > tbody > tr > td {
    padding: 13px;
}

.panel {
    border: none !important;
    background-color: unset !important;
}
</style>
