<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar
                class="list-unstyled menu-categories"
                tag="ul"
                :options="{
                    wheelSpeed: 0.5,
                    swipeEasing: !0,
                    minScrollbarLength: 40,
                    maxScrollbarLength: 300,
                    suppressScrollX: true,
                }"
            >
                <li class="menu">
                    <a
                        :href="getSlug"
                        target="_blank"
                        style="
                            background: tomato;
                            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
                            border-radius: 6px;
                            color: white;
                        "
                        class="dropdown-toggle"
                        @click="toggleMobileMenu"
                    >
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-video"
                            ></i>
                            <span>Donate Link</span>
                        </div>
                    </a>
                </li>
                <li class="menu">
                    <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="d-flex align-items-center">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-info fa-clock-rotate-left"
                            ></i>
                            <span>Dashboard</span>
                        </div>
                    </router-link>
                </li>

                <li class="menu">
                    <router-link
                    :class="activeMenu === 2 ? 'active' : ''"
                        to="/donate/transactions"
                        class="dropdown-toggle"
                        
                        @click="toggleMobileMenu"
                    >
                        <div class="">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-clock-rotate-left"
                            ></i>
                            <span>Ủng hộ</span>
                        </div>
                    </router-link>
                </li>

                <li class="menu">
                    <router-link
                        :class="activeMenu === 3 ? 'active' : ''"
                        to="/notification/donate"
                        class="dropdown-toggle"
                        @click="toggleMobileMenu"
                    >
                        <div class="">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-circle-dollar-to-slot"
                            ></i>
                            <span>Thông báo</span>
                        </div>
                    </router-link>
                </li>

                <li class="menu">
                    <router-link
                        :class="activeMenu === 4 ? 'active' : ''"
                        to="/profile/update-profile"
                        class="dropdown-toggle"
                        @click="toggleMobileMenu"
                    >
                        <div class="">
                            <i
                                style="font-size: 17px; padding-right: 13px"
                                class="fa-solid fa-user"
                            ></i>
                            <span>Thông tin</span>
                        </div>
                    </router-link>
                </li>

                <!-- <li class="menu"></li>

                 <li class="menu">
                    <a @click="onOpenModalComingSoon" class="dropdown-toggle">
                        <div class="d-flex align-items-center">
                            <i style="font-size: 17px; padding-right: 13px" class="fa-solid fa-bullseye"></i>
                            <span>Mục tiêu</span>
                        </div>
                    </a>
                    <a class="dropdown-toggle" @click="onOpenModalComingSoon">
                        <div class="d-flex align-items-center">
                            <i style="font-size: 17px; padding-right: 13px" class="fa-solid fa-ranking-star"></i>
                            <span>Top 5</span>
                        </div>
                    </a>
                </li> -->

                <!-- <li class="menu">
                    <router-link to="/update-profile" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="d-flex align-items-center">
                            <i style="font-size: 17px; padding-right: 13px" class="fa-solid fa-user"></i>
                            <span>Cập nhật thông tin</span>
                        </div>
                    </router-link>
                </li> -->
                <li>
                    <div class="p-3 mt-5" style="background: lightcyan; text-align: center">
                        <p>
                            Nâng gói dịch vụ <br />
                            để được tận hưởng <br />nhiều dịch vụ hơn
                        </p>
                        <button class="btn btn-warning" @click="goToUpdateInfo">Nâng cấp</button>
                    </div>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';

    const store = useStore();
    const route = useRoute();
    const activeMenu = ref(1);

    onMounted(() => {
        watch(
            () => route.fullPath,
            async () => {
                console.log(route.fullPath.indexOf('/donate/'))
                if (route.fullPath.indexOf('/donate/') !== -1) {
                    activeMenu.value = 2;
                    console.log('run')
                }
                if (route.fullPath.indexOf('/notification/') !== -1) {
                    activeMenu.value = 3;
                }
                if (route.fullPath.indexOf('/profile/') !== -1) {
                    activeMenu.value = 4;
                }
                if (route.fullPath.indexOf('/') !== -1) {
                    activeMenu.value = 1;
                }
            }
        );

        const selector = document.querySelector(
            '#sidebar a[href="' + window.location.pathname + '"]'
        );
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>
<script>
    export default {
        name: 'login_boxed',
        data() {
            return {
                showMenuDashDemo: false,
            };
        },
        created() {},
        computed: {
            getSlug() {
                try {
                    const data = JSON.parse(sessionStorage.getItem('userData'))?.slug;

                    if (data) {
                        return `${process.env.VUE_APP_LANDING_PAGE_URL}/${
                            JSON.parse(sessionStorage.getItem('userData')).slug
                        }`;
                    }
                    return '/pages/error404';
                } catch (error) {}
            },
            checkAdmin() {
                if (JSON.parse(sessionStorage.getItem('userData')) != null) {
                    return (
                        JSON.parse(sessionStorage.getItem('userData')) !== undefined &&
                        JSON.parse(sessionStorage.getItem('userData')).group_id !== undefined &&
                        JSON.parse(sessionStorage.getItem('userData')).group_id.role === 'admin'
                    );
                }
            },
        },
        methods: {
            onOpenModalComingSoon() {
                this.$router.push('/comming-soon');
            },
            goToUpdateInfo() {
                this.$router.push('/profile/update-profile');
            },
        },
    };
</script>
